import { activityOptions } from "../components/Utils/options";

const INITIAL_STATE = {
	userInfo: null,
	userIsLoading: true,
	clientIsSaving: false,
	userIsDeletingClient: false,
	foodLibrary: [],
	foodLibraryIsLoading: true,
	peopleList: [],
	peopleListIsLoading: true
	// cdcR: {
	// 	gender: "Female",
	// 	weight: 0,
	// 	height: 0,
	// 	age: 0,
	// 	bmr: 0,
	// 	activity: "Sedentary (little or no exercise)",
	// 	target: "Maintain Weight",
	// 	totalTargetCals: 0
	// }
};

const activityLevels = activityOptions();
const targetAmounts = ["2", "1.5", "1", "0.5"];

const LOADING_USER = "LOADING_USER";
const NOT_LOADING_USER = "NOT_LOADING_USER";
const UPDATE_USER = "UPDATE_USER";
const UPDATE_CLIENT = "UPDATE_CLIENT";
const SAVING_CLIENT = "SAVING_CLIENT";
const NOT_SAVING_CLIENT = "NOT_SAVING_CLIENT";
const DELETING_CLIENT = "DELETING_CLIENT";
const DONE_DELETING_CLIENT = "DONE_DELETING_CLIENT";
const LOADING_FOOD_LIBRARY = "LOADING_FOOD_LIBRARY";
const UPDATE_FOOD_LIB = "UPDATE_FOOD_LIB";
const LOADING_PEOPLE_LIST = "LOADING_PEOPLE_LIST";
const UPDATE_PEOPLE_LIST = "UPDATE_PEOPLE_LIST";
//
const UPDATE_CDC_STATE = "UPDATE_CDC_STATE";
const UPDATE_TARGET_WEIGHT_CALS = "UPDATE_TARGET_WEIGHT_CALS";

export default function reducer(state = INITIAL_STATE, action) {
	const { type, payload } = action;
	switch (type) {
		case LOADING_USER:
			return { ...state, userIsLoading: true };
		case NOT_LOADING_USER:
			return { ...state, userIsLoading: false };
		case UPDATE_USER || UPDATE_CLIENT:
			return {
				...state,
				userInfo: payload,
				userIsLoading: false,
				clientIsSaving: false
			};
		case SAVING_CLIENT:
			return { ...state, clientIsSaving: true };
		case NOT_SAVING_CLIENT:
			return { ...state, clientIsSaving: false };
		case DELETING_CLIENT:
			return { ...state, userIsDeletingClient: true };
		case DONE_DELETING_CLIENT:
			return { ...state, userIsDeletingClient: false };
		case LOADING_FOOD_LIBRARY:
			return { ...state, foodLibraryIsLoading: true };
		case UPDATE_FOOD_LIB:
			return {
				...state,
				foodLibrary: payload,
				foodLibraryIsLoading: false
			};
		case LOADING_PEOPLE_LIST:
			return { ...state, peopleListIsLoading: true };
		case UPDATE_PEOPLE_LIST:
			return {
				...state,
				peopleList: payload,
				peopleListIsLoading: false
			};
		case UPDATE_CDC_STATE:
			return { ...state, cdcR: payload };
		case UPDATE_TARGET_WEIGHT_CALS:
			return { ...state, cdcR: payload };

		default:
			return { ...state };
	}
}

// User
export const loadingUser = user => ({
	type: LOADING_USER
});

export const notLoadingUser = () => {
	return {
		type: NOT_LOADING_USER
	};
};

export function updateUser(user) {
	if (user && user.clients) {
		// set the real index of the client in regard to the user
		user.clients.forEach((client, i) => (client.index = `${i}`));
		user.clients.sort((a, b) =>
			a.firstname.toLowerCase() > b.firstname.toLowerCase()
				? 1
				: a.firstname.toLowerCase() < b.firstname.toLowerCase()
				? -1
				: 0
		);
		// set a different index of the client based off the sorted list
		user.clients.forEach((client, i) => (client.sortedIndex = `${i}`));
	}
	return { type: UPDATE_USER, payload: user };
}

// Client
export const savingClient = () => ({
	type: SAVING_CLIENT
});

export const notSavingClient = () => ({
	type: NOT_SAVING_CLIENT
});

export function updateClientInRedux(client, user, i) {
	if (user && client && i) {
		user.clients[i] = client;
	}
	return { type: UPDATE_CLIENT, payload: user };
}

export const deletingClient = () => ({
	type: DELETING_CLIENT
});

export const doneDeletingClient = () => ({
	type: DONE_DELETING_CLIENT
});

// Food Lib
export const loadingFoodLib = () => ({ type: LOADING_FOOD_LIBRARY });

export const updateFoodLib = lib => ({
	type: UPDATE_FOOD_LIB,
	payload: lib
});

// People List
export const loadingPeopleList = () => ({ type: LOADING_PEOPLE_LIST });

export const updatePeopleList = list => ({
	type: UPDATE_PEOPLE_LIST,
	payload: list
});

//

export function updateCDCR(cdc) {
	const bmr = parseFloat(calcBMR(cdc).toFixed(2));
	return { type: UPDATE_CDC_STATE, payload: { ...cdc, bmr } };
}

export const calcBMR = ({ gender, weight, height, age }) => {
	if (gender === "Male" || gender === "male") {
		return 66 + 6.23 * weight + 12.7 * height - 6.8 * age;
	}
	return 655 + 4.35 * weight + 4.7 * height - 4.7 * age;
};

export const calcTargetWeightCals = (cdcR, bmr, activity, target) => {
	let activityValue = 0;
	let targetAdjustment = 0;
	let newTargetVal = target.split(" ")[1];
	switch (activity) {
		case activityLevels[1]:
			activityValue = 1.375;
			break;
		case activityLevels[2]:
			activityValue = 1.55;
			break;
		case activityLevels[3]:
			activityValue = 1.725;
			break;
		case activityLevels[4]:
			activityValue = 1.9;
			break;
		default:
			activityValue = 1.2;
			break;
	}
	switch (newTargetVal) {
		case targetAmounts[0]:
			targetAdjustment = 1000;
			break;
		case targetAmounts[1]:
			targetAdjustment = 750;
			break;
		case targetAmounts[2]:
			targetAdjustment = 500;
			break;
		case targetAmounts[3]:
			targetAdjustment = 250;
			break;
		default:
			targetAdjustment = 0;
			break;
	}
	targetAdjustment = target.includes("Gain")
		? targetAdjustment
		: target.includes("Lose")
		? -targetAdjustment
		: targetAdjustment;
	const totalTargetCals = parseFloat(
		(bmr * activityValue + targetAdjustment).toFixed(2)
	);
	return {
		type: UPDATE_TARGET_WEIGHT_CALS,
		payload: { ...cdcR, totalTargetCals }
	};
};
