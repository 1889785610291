import React from "react";
import "./FPProfile.css";

const FPProfile = ({ history, match: { params } }) => {
	return (
		<div className="FPProfile-container switch-route">
			<div>{params.name}</div>
		</div>
	);
};

export default FPProfile;
