/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import "./Select.css";

const Select = ({ name, value, onChange, label, disabled, options = [] }) => {
	return (
		<div className="ne-Select-container">
			<label>{label}</label>
			<select
				name={name}
				value={value || ""}
				onChange={onChange}
				disabled={disabled}
			>
				<option>Select ⬇</option>
				{options.map((el, i) => (
					<option key={i}>{el}</option>
				))}
			</select>
		</div>
	);
};

export default Select;
