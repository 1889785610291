import React, { useState } from "react";
import "./ClientList.css";
import ClientCard from "../ClientCard/ClientCard";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Button from "../../Common/Button/Button";
import BackButton from "../../Common/Button/BackButton";
import Search from "../../Common/Search/Search";

const ClientList = ({ history, userInfo, userIsLoading }) => {
	const [searchText, updateSearchText] = useState("");
	const [appliedSearchText, updateAppliedSearchText] = useState("");
	let clientCards = <div className="text-center">Loading</div>;
	let count = -1;
	if (!userIsLoading) {
		if (userInfo) {
			clientCards = userInfo.clients
				.filter(
					client =>
						client.firstname
							.toLowerCase()
							.includes(appliedSearchText) ||
						client.lastname
							.toLowerCase()
							.includes(appliedSearchText)
				)
				.map((e, i) => <ClientCard key={i} vals={e} i={i} />);
			count = clientCards.length;
		} else {
			clientCards = <div className="text-center">Please login</div>;
		}
	}
	return (
		<div className="ClientList-container switch-route">
			<header>
				<h4 className="bg-white px-2 py-3 text-center">
					<strong>My Clients</strong>
					{count > -1 ? <span>{count}</span> : ""}
				</h4>
				<Search
					val={searchText}
					onChange={e => updateSearchText(e.target.value)}
					onSearch={() =>
						updateAppliedSearchText(searchText.toLowerCase())
					}
				/>
			</header>
			<main>{clientCards}</main>
			<footer className="footer-action-btns">
				<BackButton onClick={() => history.push("/")} />
				<Button
					type="primary"
					label="Create New Client"
					onClick={() => history.push("/create-new-client")}
					disabled={!userInfo}
				/>
			</footer>
		</div>
	);
};

function mapStateToProps({ userInfo, userIsLoading }) {
	return {
		userInfo,
		userIsLoading
	};
}

export default withRouter(connect(mapStateToProps)(ClientList));
