import React from "react";
import "./FoodCard.css";
const FoodCard = ({ name, calories, p, c, fiber, f, serving, servingType }) => {
	return (
		<section className="food-card">
			<header>
				<strong>{name}</strong>
				<span className="subtitle float-right">
					{serving} {servingType} / {calories} cals
				</span>
			</header>
			<main>
				<div className="row">
					<div className="col-3 pr-0">
						<span className="stat-pill">{p || 0} p</span>
					</div>
					<div className="col-3 p-0">
						<span className="stat-pill">{c || 0} c</span>
					</div>
					<div className="col-3 p-0">
						<span className="stat-pill">{fiber || 0} fiber</span>
					</div>
					<div className="col-3 pl-0">
						<span className="stat-pill">{f || 0} f</span>
					</div>
				</div>
			</main>
			<footer></footer>
		</section>
	);
};

export default FoodCard;
