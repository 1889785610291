import React from "react";

import Button from "../../../Common/Button/Button";
import MacrosEditor from "../../../Common/MacrosEditor/MacrosEditor";

export default ({
	toggleMacrosEditor,
	viewMeals,
	macrosEditor,
	protein,
	carbs,
	fats,
	updateState,
	readOnly,
	removeClient
}) => {
	return (
		<>
			<div
				style={{
					marginTop: 16,
					display: "flex",
					justifyContent: "space-between"
				}}
			>
				<Button
					type="primary"
					label="Macros"
					onClick={() => toggleMacrosEditor(b => !b)}
					halfWidth
				/>
				<Button
					type="primary"
					label="View Meal Plans"
					onClick={viewMeals}
					halfWidth
				/>
			</div>
			{macrosEditor && (
				<MacrosEditor
					protein={protein}
					carbs={carbs}
					fats={fats}
					updateState={updateState}
				/>
			)}
			{!readOnly && (
				<Button
					type="danger"
					label="Delete Client"
					onClick={removeClient}
					fullWidth
					style={{
						marginTop: 16
					}}
				/>
			)}
		</>
	);
};
