import React from "react";
import "./SelectOneInput.css";

const SelectOneInput = ({ label, options, onSelect, value, className }) => {
	return (
		<div className={`select-one ${className || ""}`}>
			<label>{label}</label>
			<div className="select-one-options">
				{options.map((o, i) => (
					<div
						key={o.value}
						onClick={() => onSelect(o.value)}
						className={
							value === o.value ? "selected" : ""
						}
					>
						{o.label}
					</div>
				))}
			</div>
		</div>
	);
};

export default SelectOneInput;
