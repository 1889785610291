import React from "react";
// import "./ClientSideLanding.css";

const ClientSideLanding = ({
	Link,
	FontAwesomeIcon,
	faUserPlus,
	faUser,
	faBook
}) => {
	return (
		<>
			<Link to="/find-people">
				<FontAwesomeIcon icon={faUserPlus} className="mr-3" />
				<strong>Find People</strong>
			</Link>
			<Link to="/my-plan">
				<FontAwesomeIcon icon={faBook} className="mr-3" />
				<strong>My Plan</strong>
			</Link>
			<Link to="/my-profile">
				<FontAwesomeIcon icon={faUser} className="mr-3" />
				<strong>My Profile</strong>
			</Link>
		</>
	);
};
export default ClientSideLanding;
