import React from "react";
import Button from "../../../../Common/Button/Button";
import BackButton from "../../../../Common/Button/BackButton";
import Input from "../../../../NewElements/Inputs/Input";
import "./ManualFoodEntry.css";

const ManualFoodEntry = ({
	name,
	calories,
	p,
	c,
	fiber,
	f,
	serving,
	servingType,
	setLookup,
	lookup,
	state,
	updateState,
	addFood
}) => (
	<div className="manual-entry-fields">
		<form>
			<Input
				type="text"
				name="name"
				value={name}
				onChange={updateState}
				label="Name"
			/>
			<Input
				type="text"
				name="calories"
				value={calories}
				onChange={updateState}
				label="Calories"
			/>
			<Input
				type="text"
				name="p"
				value={p}
				onChange={updateState}
				label="Protein (g)"
			/>
			<Input
				type="text"
				name="c"
				value={c}
				onChange={updateState}
				label="Carbs (g)"
			/>
			<Input
				type="text"
				name="fiber"
				value={fiber}
				onChange={updateState}
				label="Fiber (g)"
			/>
			<Input
				type="text"
				name="f"
				value={f}
				onChange={updateState}
				label="Fats (g)"
			/>
			<Input
				type="text"
				name="serving"
				value={serving}
				onChange={updateState}
				label="Serving Size (1, 1/2, etc.)"
			/>
			<Input
				type="text"
				name="servingType"
				value={servingType}
				onChange={updateState}
				label="Serving Unit (g, oz, etc.)"
			/>
		</form>
		<div className="me-btn-container">
			<BackButton onClick={() => setLookup(!lookup)} />
			<Button type="primary" label="Add" onClick={() => addFood(state)} />
		</div>
	</div>
);
export default ManualFoodEntry;
