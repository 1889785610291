import React from "react";
import css from "./ClientProfMainInfo.module.css";

import Select from "../../../NewElements/Selects/Select";

export default ({
	makeInput,
	updateState,
	readOnly,
	clientEmail,
	gender,
	height,
	weight,
	goalWeight,
	birthdate,
	updateBirthdate,
	activity,
	activityOptions,
	goal,
	goalOptions,
	bmr,
	target,
	mealCount
}) => {
	return (
		<section className="bg-white">
			{makeInput(
				"Email",
				"clientEmail",
				clientEmail,
				updateState,
				readOnly
			)}
			{makeInput("Gender", "gender", gender, updateState, readOnly)}
			{makeInput(
				"Height (in)",
				"height",
				height,
				updateState,
				readOnly,
				"0",
				"number"
			)}
			{makeInput(
				"Weight (lb)",
				"weight",
				weight,
				updateState,
				readOnly,
				"0",
				"number"
			)}
			{makeInput(
				"Goal Weight (lb)",
				"goalWeight",
				goalWeight,
				updateState,
				readOnly,
				"0",
				"number"
			)}
			{makeInput(
				"Birthdate",
				"birthdate",
				birthdate,
				updateBirthdate,
				readOnly,
				"",
				"date"
			)}
			<Select
				label="Activity"
				name="activity"
				value={activity}
				onChange={updateState}
				disabled={readOnly}
				options={activityOptions()}
			/>
			<Select
				label="Goal"
				name="goal"
				value={goal}
				onChange={updateState}
				disabled={readOnly}
				options={goalOptions()}
			/>
			{makeInput("BMR", "bmr", bmr, updateState, readOnly, "0", "text")}
			{makeInput(
				"Target Cals",
				"target",
				target,
				updateState,
				readOnly,
				"",
				"number"
			)}
			{makeInput(
				"Meal Count",
				"mealCount",
				mealCount,
				updateState,
				readOnly,
				"",
				"number"
			)}
		</section>
	);
};
