import React from "react";

export default ({ statusClass, plannedUsage, targetCalories }) => {
	return (
		<section className={`planned-target-cals ${statusClass}`}>
			<header>
				<label>Planned / Target Cals</label>
			</header>
			<span className="planned-target-cals-value">
				{plannedUsage.usedCals || 0}
			</span>
			<span>
				{" "}
				/ {targetCalories || 0}{" "}
				<span className="float-right">
					{(
						((plannedUsage.usedCals || 0) * 100) /
						(targetCalories || 1)
					).toFixed(0)}
					% of target
				</span>
			</span>
		</section>
	);
};
