/* eslint-disable no-eval */ /* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import DataCard from "../../../../Common/DataCard/DataCard";
import Button from "../../../../Common/Button/Button";
import BackButton from "../../../../Common/Button/BackButton";
import Input from "../../../../NewElements/Inputs/Input";
import "./EditItem.css";
import Fraction from "fraction.js";

const EditItem = ({
	id,
	name,
	calories,
	p,
	c,
	fiber,
	f,
	serving,
	servingType,
	original = { servingCount: false },
	submit,
	cancel,
	editMode = false
}) => {
	const [state, setState] = useState({
			id,
			name,
			calories,
			p,
			c,
			f,
			serving,
			original,
			servingType
		}),
		[input, setInput] = useState(original.servingCount || "1"),
		updateState = val => {
			const newState = {
				name,
				id,
				calories: !isNaN(calories * val) && (calories * val).toFixed(1),
				p: !isNaN(p * val) && (p * val).toFixed(1),
				c: !isNaN(c * val) && (c * val).toFixed(1),
				fiber: !isNaN(fiber * val) && (fiber * val).toFixed(1),
				f: !isNaN(f * val) && (f * val).toFixed(1),
				serving: updateServing(serving, val),
				original: {
					calories,
					p,
					c,
					f,
					fiber,
					serving,
					servingCount: val
				},
				servingType
			};
			setState(newState);
		},
		updateServing = (serving, val) => {
			let servingNumber = 0;
			let res = 0;
			if (serving.includes(" ")) {
				servingNumber = serving.split(" ")[0].trim();
			} else {
				servingNumber = serving;
			}
			if (servingNumber.includes("/")) {
				let [numer, denom] = servingNumber.trim().split("/");
				const result = numer / denom;
				res = new Fraction(result * val).toFraction(true);
			} else if (Number.parseFloat(servingNumber)) {
				servingNumber = Number.parseFloat(serving);
				res = `${servingNumber * val}`;
			} else {
				res = servingNumber;
			}
			return res;
		},
		inChange = ({ target: { value } }) => {
			setInput(value);
			updateState(value);
		},
		clear = () => {
			setState({});
			setInput("1");
			cancel();
		};

	useEffect(() => {
		if (editMode && original.servingCount) {
			updateState(original.servingCount);
		} else {
			setState({
				id,
				name,
				calories,
				p,
				c,
				fiber,
				f,
				serving,
				servingType,
				original,
				editMode
			});
		}
	}, [id, name, calories, p, c, fiber, f, serving, servingType, editMode]);

	const iStyle = {
			opacity: id ? 1 : 0,
			fontSize: id ? "" : 0,
			height: id ? "auto" : 0,
			transition: "opacity 500ms, height 750ms"
		},
		style = {
			height: id ? "100%" : 0,
			transition: "500ms",
			marginBottom: 0,
			zIndex: id ? 1 : -1,
			padding: id ? "20px 20px 60px" : 0
		};
	let servingDisplay = state.serving;
	if (state.serving && !state.serving.includes("/")) {
		try {
			const parsedServing = Number.parseFloat(state.serving);
			if (Math.floor(parsedServing) !== parsedServing) {
				servingDisplay = parsedServing.toFixed(2);
			}
		} catch (e) {
			console.error(state.serving + " is not a parsable float.");
		}
	}
	return (
		<div style={style} className="EditItem-container">
			<section className="bg-white food-card">
				<header>
					<h4 style={{ ...iStyle }}>{state.name}</h4>
				</header>
				<main style={{ ...iStyle, width: "100%" }}>
					<section className="py-3 border-top-light-grey">
						<DataCard label="Calories" value={state.calories} />
					</section>
					<section className="py-3 border-top-light-grey">
						<h5 className="text-center text-darken-75 mb-3">
							<strong>Macros</strong>
						</h5>
						<div
							style={{
								...iStyle
							}}
							className="row"
						>
							<div className="col-6">
								<DataCard label="Protein" value={state.p} />
							</div>
							<div className="col-6">
								<DataCard label="Carbs" value={state.c} />
							</div>
							<div className="col-6 mt-2">
								<DataCard
									label="Fiber"
									value={state.fiber || "-"}
								/>
							</div>
							<div className="col-6 mt-2">
								<DataCard label="Fats" value={state.f} />
							</div>
						</div>
					</section>
				</main>
			</section>
			<section className="py-3 mt-1 input-section" style={{ ...iStyle }}>
				<Input
					type="number"
					min="0"
					max="50"
					step="0.25"
					label="Servings"
					value={input === undefined ? 1 : input}
					onChange={inChange}
				/>
				{state.servingType && (
					<div className="serving-unit">
						{servingDisplay} {state.servingType}
					</div>
				)}
			</section>
			<footer style={iStyle}>
				<BackButton onClick={clear} />
				<Button
					label={editMode ? "Save" : "Add Food"}
					type="primary"
					onClick={() => submit(state)}
				/>
			</footer>
		</div>
	);
};
export default EditItem;
