import React from "react";
import "./ClientPhoto.css";

const ClientPhoto = ({ clientId, imageExtension, imageRotation, onClick }) => {
	let objectPath = "no-image-available-200.jpg";
	if (clientId && imageExtension && imageExtension !== "missing") {
		objectPath = `${clientId}.${imageExtension}`;
	}
	const backgroundImage = `url(https://s3.amazonaws.com/nutrition-tracker.devtc.pro/client-photos/${objectPath})`;
	return (
		<div
			className={`client-photo rotate-${imageRotation}`}
			style={{ backgroundImage }}
			onClick={onClick}
		/>
	);
};

export default ClientPhoto;
