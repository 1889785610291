/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./ClientProfile.css";
import { toast } from "react-toastify";
import moment from "moment";

import withAuth from "../../Auth/WithAuth";
import { updateClient, deleteClient } from "../../apiCalls";
import { getAge, calcCals } from "../../Utils";
import { activityOptions, goalOptions } from "../../Utils/options";
import Button from "../../Common/Button/Button";
import ClientProfHeader from "./ClientProfHeader/ClientProfHeader";
import ClientProfFooter from "./ClientProfFooter/ClientProfFooter";
import Toast from "../../Common/Toast/Toast";
import Overlay from "../../Common/Overlay/Overlay";
import ClientProfMainInfo from "./ClientProfMainInfo/ClientProfMainInfo";
import ClientNotes from "./ClientNotes/ClientNotes";
import ClientBtnSection from "./ClientBtnSection/ClientBtnSection";

const defaultMacros = {
	protein: 40,
	carbs: 40,
	fats: 20
};

const ClientProfile = ({
	clientIsSaving,
	savingClient,
	notSavingClient,
	userIsDeletingClient,
	deletingClient,
	doneDeletingClient,
	updateClientInRedux,
	userInfo,
	userIsLoading,
	match,
	history,
	location: { pathname },
	updateUser
}) => {
	const [readOnly, toggleEdit] = useState(true),
		[macrosEditor, toggleMacrosEditor] = useState(false),
		[state, setState] = useState(""),
		sortedIndex = match.params.i;

	useEffect(() => {
		if (
			userInfo &&
			userInfo.clients &&
			sortedIndex < userInfo.clients.length
		) {
			const client = { ...userInfo.clients[sortedIndex] };
			if (!("macros" in client)) client.macros = { ...defaultMacros };
			else {
				for (let key in client.macros) {
					client.macros[key] = +client.macros[key];
				}
			}
			setState(client);
		} else if (!userIsLoading) {
			history.push("/client-list");
		}
	}, [userInfo, sortedIndex]);

	let {
		id,
		index,
		firstname,
		lastname,
		month,
		day,
		year,
		weight,
		goalWeight,
		height,
		gender,
		goal,
		target,
		bmr,
		activity,
		mealCount = 3,
		macros,
		notes = [],
		clientEmail,
		photo,
		imageExtension,
		imageRotation
	} = state !== "" && state;
	const { protein, carbs, fats } = macros !== undefined && macros;
	mealCount = parseInt(mealCount);
	const updateState = ({ target: { value, name } }) => {
		if (name === "protein" || name === "carbs" || name === "fats") {
			const newMacros = {
				...macros,
				[name]: +value
			};
			setState(s => ({ ...s, macros: newMacros }));
		}
		setState(s => ({ ...s, [name]: value }));
	};

	const updateClientCals = age => {
		if (!state || !goal || !activity) {
			toast.error(
				<Toast type="error">Please Set Goal and Activity</Toast>
			);
		} else if (height === "0" || weight === "0") {
			toast.error(<Toast type="error">Missing Height / Weight</Toast>);
		} else {
			const calc = calcCals({ ...state, age });
			const newState = { ...state, ...calc };
			setState(newState);
			updateClientInRedux(newState, userInfo, sortedIndex);
			if (readOnly) toggleEdit(!readOnly);
		}
	};

	let age = "",
		date = "",
		birthdate = "";

	if (userInfo && userInfo.clients && month) {
		try {
			if (month.length === 2) {
				date = new Date(`${year}-${month}-${day}`);
			} else {
				date = new Date(`${month} ${day}, ${year}`);
			}
			if (!isNaN(date.getTime())) {
				age = getAge(date);
				birthdate = date.toISOString().split("T")[0];
			} else {
				toast.error(<Toast type="error">Error Loading Birthday</Toast>);
			}
		} catch (err) {
			toast.error(<Toast type="error">Error Loading Birthday</Toast>);
		}
	}
	const calcBtn = (
		<Button
			label="Calculate Cals"
			type="secondary"
			onClick={() => updateClientCals(age)}
		/>
	);

	const fullName = firstname + " " + lastname;

	const viewMeals = () => {
		if (!target) {
			toast.error(
				<Toast type="error">
					Please Set Target Calories to View Meals
				</Toast>
			);
		} else {
			history.push(`${pathname}/meal-plans`);
		}
	};

	const removeClient = () => {
		deletingClient();
		const toDeleteIndex = userInfo.clients[sortedIndex].index;
		deleteClient(userInfo.email, toDeleteIndex, () => {
			const userCopy = { ...userInfo };
			userCopy.clients.splice(sortedIndex, 1);
			updateUser(userCopy);
			history.push("/client-list");
			doneDeletingClient();
			toast.success(<Toast type="success">Deleted {fullName}</Toast>);
		});
	};

	const onSave = async () => {
		if (protein + carbs + fats !== 100)
			return toast.error(
				<Toast type="error">Macros must equal 100%</Toast>
			);
		const newState = { ...state };
		if ("macros" in newState) {
			newState.macros = {
				protein: protein.toString(),
				carbs: carbs.toString(),
				fats: fats.toString()
			};
		}
		savingClient();
		if (await updateClient(newState, userInfo, index)) {
			updateClientInRedux(newState, userInfo, sortedIndex);
			toggleEdit(!readOnly);
			toast.success(
				<Toast type="success">Saved Changes to {firstname}</Toast>
			);
		}
		notSavingClient();
	};

	const onImageChange = ({ image, extension, rotation }) =>
		setState({
			...state,
			photo: image,
			imageExtension: extension,
			imageRotation: rotation
		});

	const updateBirthdate = event => {
		const split = event.target.value.split("-");
		const newYear = split[0];
		const newMonth = split[1];
		const newDay = split[2];
		setState({
			...state,
			year: newYear,
			month: newMonth,
			day: newDay
		});
	};

	return (
		<div className="ClientProfile-container switch-route">
			{state && (
				<>
					<ClientProfHeader
						id={id}
						fullName={fullName}
						firstname={firstname}
						lastname={lastname}
						age={age}
						date={date}
						moment={moment}
						gender={gender}
						weight={weight}
						height={height}
						readOnly={readOnly}
						newPhoto={photo}
						imageExtension={imageExtension}
						imageRotation={imageRotation}
						onImageChange={onImageChange}
					/>
					<main>
						<ClientProfMainInfo
							makeInput={makeInput}
							updateState={updateState}
							readOnly={readOnly}
							clientEmail={clientEmail}
							gender={gender}
							height={height}
							weight={weight}
							goalWeight={goalWeight}
							birthdate={birthdate}
							updateBirthdate={updateBirthdate}
							activity={activity}
							activityOptions={activityOptions}
							goal={goal}
							goalOptions={goalOptions}
							bmr={bmr}
							target={target}
							mealCount={mealCount}
						/>
						<ClientBtnSection
							toggleMacrosEditor={toggleMacrosEditor}
							viewMeals={viewMeals}
							macrosEditor={macrosEditor}
							protein={protein}
							carbs={carbs}
							fats={fats}
							updateState={updateState}
							readOnly={readOnly}
							removeClient={removeClient}
						/>
						<hr />
						<ClientNotes
							notes={notes}
							updateState={updateState}
							readOnly={readOnly}
						/>
					</main>
					<ClientProfFooter
						readOnly={readOnly}
						history={history}
						calcBtn={calcBtn}
						toggleEdit={() => toggleEdit(b => !b)}
						cancel={() => {
							// reset values on cancel
							const client = { ...userInfo.clients[sortedIndex] };
							if (!("macros" in client))
								client.macros = { ...defaultMacros };
							setState(client);
							toggleEdit(b => !b);
						}}
						onSave={onSave}
						state={state}
						userInfo={userInfo}
						index={index}
					/>
				</>
			)}
			{clientIsSaving && (
				<Overlay>
					<strong>Saving Changes</strong>
				</Overlay>
			)}
			{userIsDeletingClient && (
				<Overlay>
					<strong>Deleting Client</strong>
				</Overlay>
			)}
		</div>
	);
};

export default withAuth(ClientProfile);

function makeInput(label, name, value, onChange, readOnly, min = "", type) {
	return (
		<div className="field">
			<label>{label}</label>
			<input
				name={name}
				value={value || ""}
				onChange={onChange}
				readOnly={readOnly}
				min={min}
				type={type}
			/>
		</div>
	);
}
