import React from "react";

import MultiAddInput from "../../../Common/MultiAddInput/MultiAddInput";

export default ({ notes, updateState, readOnly }) => {
	return (
		<MultiAddInput
			label="Notes"
			itemLabel="Note"
			items={notes}
			onAdd={() => {
				const newNotes = notes && notes.length ? ["", ...notes] : [""];
				updateState({
					target: { name: "notes", value: newNotes }
				});
			}}
			onRemove={index => {
				const newNotes = [...notes];
				newNotes.splice(index, 1);
				updateState({
					target: { name: "notes", value: newNotes }
				});
			}}
			onUpdate={(index, value) => {
				const newNotes = [...notes];
				newNotes[index] = value;
				updateState({
					target: { name: "notes", value: newNotes }
				});
			}}
			style={{ marginTop: 20 }}
			readonly={readOnly}
		/>
	);
};
