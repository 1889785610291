/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import "./MealCard.css";

// import MacroPercent from "../MacroPercent/MacroPercent";
import Button from "../Common/Button/Button";
import { areArraysEqual } from "../Utils";
import FoodItem from "./FoodItem/FoodItem";

const MealCard = ({
	calories,
	mealCount,
	index,
	foodModal,
	openEditFoodModal,
	meal = { foods: [] },
	name = "",
	updateMeal,
	updateMealName
}) => {
	const [foods, setFoods] = useState(meal.foods),
		[expand, setExpand] = useState(true);

	useEffect(() => {
		// if (foods.length === 0 && food && food.foods) setFoods(food.foods);
		if (
			foods.length === 0 &&
			meal &&
			meal.foods &&
			meal.foods.length !== foods.length
		) {
			setFoods(meal.foods);
		} else if (
			foods.length > 0 &&
			meal &&
			meal.foods &&
			(meal.foods.length > foods.length ||
				areArraysEqual(meal.foods, foods))
		) {
			setFoods(meal.foods);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meal, foods]);

	const animation = t => `slide-in ${t}s ease-in-out 1`;

	// const enterPress = ({ charCode, target: { value } }) =>
	// 	charCode === 13 && setFoods([...foods, value]);

	// const updateInput = ({ target: { value } }) => setInput(value);

	const newAdd = () => {
			foodModal({ index, lookup: true, name });
		},
		removeFood = (meal, i) => {
			let copy = foods.slice();
			copy.splice(i, 1);
			setFoods(copy);
			updateMeal(meal, index, i);
		};
	let caloriesUsed = 0;
	const foodMap = foods.map((el, i) => {
		caloriesUsed += parseInt(el.calories);
		const servingCheck =
			(el.serving && el.serving.split(" ").length > 1) || el.servingType;
		// const pluralType = !servingCheck && parseFloat(el.serving) > 1;
		// const newServing = pluralType ? `${el.serving}s` : el.serving;
		return (
			<FoodItem
				key={i}
				i={i}
				el={el}
				servingCheck={servingCheck}
				removeFood={removeFood}
				editItem={() =>
					openEditFoodModal({
						index,
						lookup: false,
						values: el,
						edit: i,
						name
					})
				}
			/>
		);
	});

	return (
		<section
			className="MealCard-container"
			style={{
				animation: animation(1 + 0.25 * index),
				height: expand ? "31vh" : "42px"
			}}
		>
			<header className="px-3 py-2" onClick={() => setExpand(!expand)}>
				<div className="row">
					<div
						className="col-4"
						onClick={e => e.stopPropagation()}
						// style={{ position: "relative", zIndex: 100 }}
					>
						<input
							className="text-blue strong input-meal-name"
							value={name}
							onChange={({ target: { value } }) =>
								updateMealName(index, value)
							}
						/>
					</div>
					<div className="col-8 text-right calories-remaining">
						{caloriesUsed.toFixed(1)} cals used
					</div>
				</div>
			</header>
			<main className="px-3 pt-2 pb-3">
				{!isNaN(calories / mealCount) ? (
					<>
						<ul className="meal-card-foods">
							{!foods.length ? <li>No Foods Added</li> : foodMap}
						</ul>
						<section className="meal-card-btns p-2">
							<Button
								type="primary"
								label="Add Food"
								onClick={newAdd}
								fullWidth
							/>
						</section>
					</>
				) : (
					<div>Please Enter Calorie Amount</div>
				)}
			</main>
		</section>
	);
};
export default MealCard;

{
	// may move macro stuff to different component sometime
	// const [macros, setMacros] = useState({
	// 	protein: 40,
	// 	carbs: 40,
	// 	fats: 20
	// });
	// const [showMacroSelect, toggleMacroSelect] = useState(false);
	// const { protein, carbs, fats } = macros;
	// const percents = new Array(101)
	// 	.fill(0)
	// 	.map((el, i) => <option key={i}>{i}</option>);
	// const changeMacros = ({ target: { name, value } }) =>
	// 	setMacros({ ...macros, [name]: parseInt(value) });
	// const totalPercent = protein + carbs + fats;
}
{
	/* <section>
						<div>Macro Percentages: </div>
						{showMacroSelect && (
							<MacroPercent
								changeMacros={changeMacros}
								totalPercent={totalPercent}
								macros={macros}
								percents={percents}
							/>
						)}
						<button onClick={() => toggleMacroSelect(!showMacroSelect)}>
							Select Macro Percentages
						</button>
					</section> */
}
