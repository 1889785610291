import React from "react";
import "./Button.css";

const Button = ({
	type = "secondary", // primary or secondary
	label,
	onClick = e => onClick(e),
	fullWidth,
	halfWidth,
	style = {},
	className = "",
	...props
}) => {
	if (fullWidth) style.width = "100%";
	if (halfWidth) style.width = "47.5%";
	return (
		<button
			className={`${type} ${className}`}
			onClick={onClick}
			style={style}
			{...props}
		>
			{label}
		</button>
	);
};

export default Button;
