import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMale } from "@fortawesome/free-solid-svg-icons";
import { faFemale } from "@fortawesome/free-solid-svg-icons";
import ClientPhoto from "../../../Common/ClientPhoto/ClientPhoto";
import ImageInput from "../../../Common/ImageInput/ImageInput";
import "./ClientProfHeader.css";

const ClientProfHeader = ({
	readOnly,
	id,
	fullName,
	firstname,
	lastname,
	age,
	gender,
	weight,
	height,
	newPhoto,
	imageExtension,
	imageRotation,
	onImageChange
}) => {
	let heightElement = "";
	if (height) {
		heightElement = (
			<span className="prof-detail">
				{Math.floor(height / 12)}&apos; {height % 12}&quot;
			</span>
		);
	}
	let weightElement = "";
	if (weight) {
		weightElement = <span className="prof-detail">{weight} lbs</span>;
	}
	const name = fullName.length <= 15 ? fullName : `${firstname} ${lastname}`;
	let photoElement;
	if (readOnly) {
		photoElement = (
			<ClientPhoto
				clientId={id}
				imageExtension={imageExtension}
				imageRotation={imageRotation}
			/>
		);
	} else {
		let objectPath = "no-image-available-200.jpg";
		if (imageExtension && imageExtension !== "missing") {
			objectPath = `${id}.${imageExtension}`;
		}
		photoElement = (
			<ImageInput
				value={
					newPhoto ||
					`https://s3.amazonaws.com/nutrition-tracker.devtc.pro/client-photos/${objectPath}`
				}
				extension={imageExtension}
				rotation={imageRotation}
				onChange={onImageChange}
			/>
		);
	}
	return (
		<header className="ClientProfHeader-container">
			<h4 className="text-center bg-white px-2 py-3">
				<div className="photo-background"></div>
				{photoElement}
				<strong>{name}</strong>
			</h4>
			<div className="prof-details">
				<div>
					<span className="prof-detail">
						<FontAwesomeIcon
							icon={gender === "Male" ? faMale : faFemale}
						/>
					</span>
					<span className="prof-detail">{age || ""}</span>
					{heightElement}
					{weightElement}
				</div>
			</div>
		</header>
	);
};
export default ClientProfHeader;
