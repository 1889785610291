import React from "react";
import "./ClientMealPlanCard.css";

const ClientMealPlanCard = ({ mealPlan, index, history, pathname }) => {
	const navigateToMealPlan = () => {
		history.push(`${pathname}/${index}`);
	};
	let mealElements;
	let planCalories = 0;
	let planProtein = 0;
	let planCarbs = 0;
	let planFats = 0;
	let planFiber = 0;
	if (mealPlan.meals && mealPlan.meals.length) {
		mealPlan.meals.forEach(meal => {
			meal.foods.forEach(food => {
				planCalories += parseFloat(food.calories) || 0;
				planCarbs += parseFloat(food.c) || 0;
				planFats += parseFloat(food.f) || 0;
				planFiber += parseFloat(food.fiber) || 0;
				planProtein += parseFloat(food.p) || 0;
			});
		});
		mealElements = mealPlan.meals.map((meal, i) => {
			const totalMealCalories = meal.foods
				.map(food => parseFloat(food.calories))
				.reduce((total, calories) => total + calories, 0);
			return (
				<li key={i}>
					<header>
						<strong>{meal.name || "Untitled Meal"}</strong>
						<span>{totalMealCalories} calories</span>
					</header>
					<main>
						<ul>
							{meal.foods.length
								? meal.foods.map((food, j) => (
										<li key={j}>
											{food.serving} {food.servingType}{" "}
											{food.name}
										</li>
								  ))
								: "No Foods Added"}
						</ul>
					</main>
				</li>
			);
		});
	}
	return (
		<section className="ClientMealPlanCard" onClick={navigateToMealPlan}>
			<header>
				<strong>{mealPlan.name}</strong>
				<span>{planCalories} calories</span>
				<div className="mt-1">
					<span className="stat-pill">
						{planProtein.toFixed(1)} p
					</span>
					<span className="stat-pill">{planCarbs.toFixed(1)} c</span>
					<span className="stat-pill">
						{planFiber.toFixed(1)} fiber
					</span>
					<span className="stat-pill">{planFats.toFixed(1)} f</span>
				</div>
			</header>
			<main>
				<ul>{mealElements}</ul>
			</main>
		</section>
	);
};

export default ClientMealPlanCard;
