import React, { useState, useEffect } from "react";
import "./Account.css";

import Button from "../Common/Button/Button";
import Input from "../NewElements/Inputs/Input";
import withAuth from "../Auth/WithAuth";
import { pswdChange, verifyEmail, updateFireBaseName } from "../apiCalls/auth";

const Account = ({ userInfo, toast }) => {
	// state
	const [newPswd, setNewPswd] = useState(false),
		[showPswd, setShowPswd] = useState(false),
		[pswdInput, setPswdInput] = useState("");
	// methods
	// api
	const submitNewPswd = async () => {
		try {
			const res = await pswdChange(userInfo.idToken, pswdInput);
			const user = JSON.parse(atob(localStorage.getItem("uDa")));
			if (user && user.idToken) {
				user.idToken = res.idToken;
				localStorage.setItem("uDa", `${btoa(JSON.stringify(user))}`);
				toast.success("Success");
				setNewPswd(false);
				setShowPswd("");
			} else {
				toast.error(
					"An error occurred. Please try again later or contact support."
				);
			}
		} catch (err) {
			console.error(err.response.data.error.message);
			toast.error(err.response.data.error.message);
		}
	};
	useEffect(() => {
		if (userInfo && userInfo.idToken && !userInfo.displayName) {
			const { idToken, firstname, lastname } = userInfo;
			const user = JSON.parse(atob(localStorage.getItem("uDa")));
			user.displayName = `${firstname} ${lastname}`;
			updateFireBaseName(idToken, `${firstname} ${lastname}`);
			localStorage.setItem("uDa", `${btoa(JSON.stringify(user))}`);
		}
	}, [userInfo]);
	let userInfoJSX;
	if (userInfo) {
		userInfoJSX = (
			<>
				<li>
					<strong>First Name</strong>
					<div>{userInfo.firstname}</div>
				</li>
				<li>
					<strong>Last Name</strong>
					<div>{userInfo.lastname}</div>
				</li>
				<li>
					<strong>Email</strong>
					<div>{userInfo.email}</div>
				</li>
				<li>
					<strong>Client Count</strong>
					<div>{userInfo.clients.length}</div>
				</li>
				<li>
					<strong>
						{userInfo.emailVerified ? (
							<span style={{ color: "springgreen" }}>
								Email Verified
							</span>
						) : (
							<span style={{ color: "orangered" }}>
								Email Needs Verified
							</span>
						)}
					</strong>
				</li>
			</>
		);
	} else {
		userInfoJSX = <li>Loading</li>;
	}
	return (
		<div className="Account-container switch-route">
			<header className="bg-offwhite py-3 text-center">
				<h4>
					<strong>My Account</strong>
				</h4>
			</header>
			<main>
				<ul>{userInfoJSX}</ul>
				<form
					style={styles.newPswd(newPswd)}
					onSubmit={e => {
						e.preventDefault();
						submitNewPswd();
					}}
				>
					<div className="password-field">
						<Input
							type={showPswd ? "text" : "password"}
							name="newPassword"
							label="New Password"
							value={pswdInput}
							onChange={e => setPswdInput(e.target.value)}
							required={newPswd}
							minLength="8"
						/>
						<div
							title="Show Password"
							className="show-pswd-btn"
							onClick={e => {
								e.preventDefault();
								setShowPswd(s => !s);
							}}
						>
							{showPswd ? "Hide" : "Show"}
						</div>
					</div>
					<input type="Submit" />
				</form>
				{userInfo && (
					<Button
						label={newPswd ? "Cancel" : "Change Password"}
						className="my-account-btn"
						onClick={() => setNewPswd(b => !b)}
					/>
				)}
				{userInfo && !userInfo.emailVerified && (
					<Button
						label="Send Verification Email"
						className="my-account-btn"
						onClick={() => verifyEmail(userInfo.idToken)}
					/>
				)}
			</main>
		</div>
	);
};

const styles = {
	newPswd: bool => ({
		height: bool ? "20%" : 0
	})
};

export default withAuth(Account);
