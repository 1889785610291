import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';

const BackButton = ({
	fullWidth,
	...props
}) => {
  const style = {
    width: 42,
    textAlign: 'center',
    fontSize: 20
  }
	if (fullWidth) {
		style.width = "100%";
	}
	return (
		<Button
      type="secondary"
      style={style}
      label={<FontAwesomeIcon icon={faAngleLeft} style={{marginLeft: -5}} />}
      {...props}
    />
	);
};

export default BackButton;
