import React, { useState, useEffect } from "react";

import "./ClientMealPlan.css";

import { updateClient, sendClientEmail } from "../../../apiCalls";
import { toast } from "react-toastify";
import MealCard from "../../../MealCard/MealCard";
import Input from "../../../NewElements/Inputs/Input";
import DropDownInput from "../../../Common/DropDownInput/DropDownInput";
import Button from "../../../Common/Button/Button";
import BackButton from "../../../Common/Button/BackButton";
import Modal from "../../../Common/Modal/Modal";
import Toast from "../../../Common/Toast/Toast";
import MealModal from "./MealModal/MealModal";
import MealsStats from "./MealsStats/MealsStats";
import EditMealFoodModal from "./EditMealFoodModal/EditMealFoodModal";

import withAuth from "../../../Auth/WithAuth";
import { addMetrics } from "../../../Utils/index";

const getDefaultMealPlan = mealCnt => ({
	name: "Untitled Meal Plan",
	meals: new Array(mealCnt)
		.fill({ foods: [] })
		.map((o, i) => ({ ...o, name: `Meal ${i + 1}` }))
});

const ClientMealPlan = ({
	updateClientInRedux,
	userInfo,
	match: { params },
	foodLibrary,
	history
}) => {
	const [showMeals] = useState(true),
		[showFoodModal, toggleFoodModal] = useState(false),
		[showEditFoodModal, toggleEditFoodModal] = useState(false),
		[modalState, setModalState] = useState({}),
		[mealCount, setMealCount] = useState(1),
		[calories, setCalories] = useState(0),
		[client, setClient] = useState(null),
		[plannedUsage, setPlannedUsage] = useState(null),
		[mealPlan, setMealPlan] = useState(null),
		[mealPlanName, setMealPlanName] = useState(""),
		clientIndex = params.i,
		mealPlanIndex = params.mealPlan;

	useEffect(() => {
		if (!client && userInfo) {
			const selectedClient = userInfo.clients[clientIndex];
			setClient(selectedClient);
			setCalories(selectedClient.target);

			let selectedMealPlan = selectedClient.mealPlans[mealPlanIndex];
			if (!selectedMealPlan) {
				let mealCnt = parseInt(selectedClient.mealCount);
				setMealCount(mealCnt);
				selectedMealPlan = getDefaultMealPlan(mealCnt);
			} else {
				setMealCount(selectedMealPlan.meals.length);
			}
			setMealPlan(selectedMealPlan);
			setMealPlanName(selectedMealPlan.name);

			setPlannedUsage(addMetrics(selectedMealPlan.meals));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client, userInfo, clientIndex]);

	const updateState = ({ target: { value } }) => {
		setMealCount(parseInt(value));
		if (mealPlan.meals.length < value) {
			const newMeals = value - mealPlan.meals.length;
			for (let i = 0; i < newMeals; i++) {
				mealPlan.meals.push({
					foods: [],
					name: `Meal ${1 + mealPlan.meals.length}`
				});
			}
		} else if (mealPlan.meals.length > value) {
			mealPlan.meals = mealPlan.meals.slice(0, value);
		}
		setMealPlan({ ...mealPlan });
	};

	const setFoodModalFn = obj => {
		toggleFoodModal(true);
		setModalState(obj);
	};

	const openEditFoodModal = obj => {
		toggleEditFoodModal(true);
		setModalState(obj);
	};

	const updateMeal = (food, mealIndex, removedFoodIndex, editFoodIndex) => {
		const mealsCopy = mealPlan.meals.slice();
		const copiedMeal = mealsCopy[mealIndex];
		const name = (copiedMeal && copiedMeal.name) || `Meal ${mealIndex + 1}`;
		const oldFoods = (copiedMeal && copiedMeal.foods) || [];
		if (!isNaN(editFoodIndex)) {
			oldFoods.splice(editFoodIndex, 1, food);
			mealsCopy[mealIndex] = {
				name,
				foods: [...oldFoods]
			};
		} else if (!isNaN(removedFoodIndex)) {
			oldFoods.splice(removedFoodIndex, 1);
			mealsCopy[mealIndex] = {
				name,
				foods: [...oldFoods]
			};
		} else {
			mealsCopy[mealIndex] = {
				name,
				foods: [...oldFoods, food]
			};
		}
		mealPlan.meals = mealsCopy;
		setMealPlan({ ...mealPlan });
		setPlannedUsage(addMetrics(mealsCopy));
	};

	const updateMealName = (i, text) => {
		const mealsCopy = mealPlan.meals.slice();
		mealsCopy[i].name = text;
		mealPlan.meals = mealsCopy;
		setMealPlan({ ...mealPlan });
	};

	const onSave = async () => {
		const { email } = userInfo;
		mealPlan.name = mealPlanName;
		client.mealPlans[mealPlanIndex] = mealPlan;
		updateClientInRedux(client, userInfo, clientIndex);
		if (await updateClient({ ...client }, { email }, clientIndex)) {
			toast.success(<Toast type="success">Saved</Toast>);
		} else {
			toast.error(<Toast type="error">Failed to Save</Toast>);
		}
	};

	const deletePlan = async () => {
		if (window.confirm("Are you sure you want to delete this plan?")) {
			const { email } = userInfo;
			client.mealPlans.splice(mealPlanIndex, 1);
			updateClientInRedux(client, userInfo, clientIndex);
			if (await updateClient({ ...client }, { email }, clientIndex)) {
				toast.success(<Toast type="success">Plan Deleted</Toast>);
				history.push(
					`/client/${clientIndex}/${client.firstname}-${client.lastname}/meal-plans`
				);
			} else {
				toast.error(<Toast type="error">Failed to Delete Plan</Toast>);
			}
		}
	};

	const sendMeals = () => {
		const { clientEmail } = client;
		const { firstname, lastname, email } = userInfo;
		mealPlan.meals.forEach(meal =>
			meal.foods.forEach(food => {
				if (!food.fiber) food.fiber = "0.0";
			})
		);
		const requestBody = {
			userEmail: email,
			clientEmail,
			firstname,
			lastname,
			planName: mealPlan.name,
			meals: mealPlan.meals
		};
		if (!clientEmail || clientEmail === "missing") {
			toast.error(<Toast type="error">Client has no email set.</Toast>);
		} else {
			sendClientEmail(requestBody, res => {
				if (res.data === "success") {
					toast.success(
						<Toast type="success">Emailed successfully</Toast>
					);
				} else {
					toast.error(<Toast type="error">Email failed.</Toast>);
				}
			});
		}
	};
	let name = "Loading";
	if (client) {
		name = `${client.firstname}'s Meal Plan`;
	}

	const checkForMealName = (meal, i) => {
		return (
			(meal && !("name" in meal) && `Meal ${i + 1}`) ||
			(meal && "name" in meal && meal.name)
		);
	};

	return (
		<div className="ClientMealPlan-container switch-route">
			{showFoodModal && (
				<Modal>
					<MealModal
						foodLib={foodLibrary}
						modalState={modalState}
						updateMeal={updateMeal}
						toggleFoodModal={toggleFoodModal}
						showFoodModal={showFoodModal}
					/>
				</Modal>
			)}
			{showEditFoodModal && (
				<Modal>
					<EditMealFoodModal
						modalState={modalState}
						save={updateMeal}
						close={() => toggleEditFoodModal(false)}
					/>
				</Modal>
			)}
			<header className="py-3 bg-white text-center px-2">
				<h4>
					<strong>{name}</strong>
				</h4>
			</header>
			<main>
				<div className="row">
					<div className="col-8 pr-1">
						<Input
							type="text"
							name="mealPlanName"
							value={mealPlanName}
							onChange={e => setMealPlanName(e.target.value)}
							label="Meal Plan Name"
						/>
					</div>
					<div className="col-4 pl-1">
						<DropDownInput
							name="mealCount"
							label="Meal Count"
							options={new Array(12)
								.fill()
								.map((e, i) => ({ value: i + 1 }))}
							value={mealCount}
							onChange={updateState}
						/>
					</div>
				</div>
				{plannedUsage && (
					<MealsStats
						plannedUsage={plannedUsage}
						targetCalories={calories}
						macros={client.macros}
					/>
				)}
				<section>
					{showMeals &&
						mealCount > 0 &&
						calories > 0 &&
						mealPlan.meals.length > 0 &&
						mealPlan.meals.map((meal, i) => (
							<MealCard
								key={i}
								index={i}
								calories={calories}
								meal={meal}
								name={checkForMealName(meal, i)}
								updateMealName={updateMealName}
								updateMeal={updateMeal}
								mealCount={mealCount}
								foodModal={setFoodModalFn}
								openEditFoodModal={openEditFoodModal}
							/>
						))}
				</section>
				<Button
					type="secondary"
					label="Delete Plan"
					fullWidth
					className="mb-3 fw-700 text-red"
					onClick={deletePlan}
				/>
			</main>
			<footer className="footer-action-btns">
				<BackButton
					onClick={() =>
						history.push(
							`/client/${clientIndex}/${client.firstname}-${client.lastname}/meal-plans`
						)
					}
				/>
				<Button label="Save" type="primary" onClick={onSave} />
				<Button
					label="Email Plan"
					type="primary"
					disabled={
						!mealPlan || !mealPlan.meals || !mealPlan.meals.length
					}
					onClick={sendMeals}
				/>
			</footer>
		</div>
	);
};
export default withAuth(ClientMealPlan);
