import React, { useState } from "react";
import "./NavBar.css";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils, faBars } from "@fortawesome/free-solid-svg-icons";
import withAuth from "../Auth/WithAuth";

const NavBar = ({ userInfo, updateUser, history }) => {
	const [state, setState] = useState({
		height: 0,
		display: "none"
	});

	const logout = event => {
		event.preventDefault();
		history.push("/login");
		localStorage.removeItem("uDa");
		updateUser(null);
	};
	const changeHeight = height => {
		const newState = { ...state, height: height === 0 ? "40vh" : 0 };
		setState(newState);
		state.height === 0
			? changeDisplay(newState, true)
			: changeDisplay(newState, false);
	};

	const changeDisplay = (newState, bool) => {
		bool
			? setTimeout(() => {
					setState({ ...newState, display: "initial" });
			  }, 250)
			: setState({ ...newState, display: "none" });
	};

	const animation = t => `slide-in ${t}s ease-in-out 1`;
	const { height, display } = state;

	return (
		<>
			<div className="navbar-container">
				<h1 onClick={() => history.push("/")} className="pointer">
					<FontAwesomeIcon icon={faUtensils} />
					MealPlanner
				</h1>
				<div className="desktop-links d-none d-lg-block">
					<Link to="/">Home</Link>
					{userInfo && <Link to="/my-account">My Account</Link>}
					<Link to="/about">About</Link>
					{!userInfo && <Link to="/login">Login</Link>}
					{userInfo && (
						<a href="/logout" onClick={logout}>
							Log out
						</a>
					)}
				</div>
				<div
					className="d-block d-lg-none"
					style={{
						fontSize: 24,
						transform:
							height === 0 ? "rotateZ(0deg)" : "rotateZ(90deg)",
						transition: "500ms"
					}}
					onClick={() => changeHeight(height)}
				>
					<FontAwesomeIcon icon={faBars} />
				</div>
			</div>
			<div
				className="navbar-container-expanded"
				style={{ height, transition: "500ms" }}
			>
				<Link
					to="/"
					style={{
						display,
						animation: animation(0.5),
						MozAnimation: animation(0.5),
						WebkitAnimation: animation(0.5)
					}}
					onClick={() => changeHeight(height)}
				>
					Home
				</Link>
				{userInfo && (
					<Link
						to="/my-account"
						style={{
							display,
							animation: animation(0.75),
							MozAnimation: animation(0.75),
							WebkitAnimation: animation(0.75)
						}}
						onClick={() => changeHeight(height)}
					>
						My Account
					</Link>
				)}
				<Link
					to="/about"
					style={{
						display,
						animation: animation(1),
						MozAnimation: animation(1),
						WebkitAnimation: animation(1)
					}}
					onClick={() => changeHeight(height)}
				>
					About
				</Link>
				{!userInfo && (
					<Link
						to="/login"
						style={{
							display,
							animation: animation(1.25),
							MozAnimation: animation(1.25),
							WebkitAnimation: animation(1.25)
						}}
						onClick={() => changeHeight(height)}
					>
						Login
					</Link>
				)}
				{userInfo && (
					<a
						href="/logout"
						onClick={e => {
							logout(e);
							changeHeight(height);
						}}
						style={{
							display,
							animation: animation(1.25),
							MozAnimation: animation(1.25),
							WebkitAnimation: animation(1.25)
						}}
					>
						Log out
					</a>
				)}
			</div>
		</>
	);
};

export default withAuth(NavBar);
