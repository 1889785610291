import React from "react";
import "./DataCard.css";

const DataCard = ({
	value,
	label,
	style = {},
  fullWidth,
  className = '',
}) => {
	if (fullWidth) {
		style.width = "100%";
	}
	return (
		<dl className={`DataCard ${className}`} style={style}>
      <dt>{value}</dt>
      <dd>{label}</dd>
		</dl>
	);
};

export default DataCard;
