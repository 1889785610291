import { getFoodLibrary } from "../components/apiCalls";

const FL_EXPIRY_KEY = "foodLibraryExpiry";
const FL_KEY = "foodLibrary";

export const getCachedFoodLibrary = async () => {
	let localFoodLibrary = null;
	const expiry = await localStorage.getItem(FL_EXPIRY_KEY);
	let cacheIsValid = expiry && new Date(expiry) > new Date();
	if (cacheIsValid) {
		const rawLocal = await localStorage.getItem(FL_KEY);
		localFoodLibrary = JSON.parse(rawLocal);
	}
	return localFoodLibrary;
};

export const updateFoodLibraryCache = async () =>
	await getFoodLibrary().then(foodLibrary => {
		localStorage.setItem(FL_KEY, JSON.stringify(foodLibrary));
		let expiry = new Date();
		expiry.setDate(expiry.getDate() + 1);
		localStorage.setItem(FL_EXPIRY_KEY, expiry.toISOString());
		return foodLibrary;
	});
