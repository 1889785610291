import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const FoodItem = ({ el, i, servingCheck, removeFood, editItem }) => {
	return (
		<li>
			<header>
				<strong onClick={editItem}>
					{el.serving} {el.servingType} {servingCheck && "of"}{" "}
					{el.name}
				</strong>
				<button
					className="float-right text-darken-50"
					onClick={() => removeFood(el, i)}
				>
					<FontAwesomeIcon icon={faTimes} />
				</button>
			</header>
			<main>
				<span className="stat-pill">{el.calories} cal</span>
				<span className="stat-pill">{el.p} p</span>
				<span className="stat-pill">{el.c} c</span>
				<span className="stat-pill">{el.fiber || "0.0"} fiber</span>
				<span className="stat-pill">{el.f} f</span>
			</main>
		</li>
	);
};
export default FoodItem;
