import React from "react";
import { Route } from "react-router-dom";

import CreateClient from "../components/Clients/CreateClient/CreateClient";
import ClientList from "../components/Clients/ClientList/ClientList";
import ClientProfile from "../components/Clients/ClientProfile/ClientProfile";
import ClientMealPlans from "../components/Clients/ClientProfile/ClientMealPlans/ClientMealPlans";
import ClientMealPlan from "../components/Clients/ClientProfile/ClientMealPlan/ClientMealPlan";
import FoodLibrary from "../components/FoodLibrary/FoodLibrary";

const TrainerRoutes = () => (
	<>
		<Route path="/create-new-client" component={CreateClient} />
		<Route path="/client-list" component={ClientList} />
		<Route exact path="/client/:i/:name" component={ClientProfile} />
		<Route
			exact
			path="/client/:i/:name/meal-plans"
			component={ClientMealPlans}
		/>
		<Route
			exact
			path="/client/:i/:name/meal-plans/:mealPlan"
			component={ClientMealPlan}
		/>
		<Route exact path="/food-library" component={FoodLibrary} />
	</>
);

export default TrainerRoutes;
