import React from "react";
import "./ClientCard.css";
import { withRouter } from "react-router-dom";
import ClientPhoto from "../../Common/ClientPhoto/ClientPhoto";
// import moment from "moment";

const ClientCard = ({
	vals: {
		id,
		imageExtension,
		imageRotation,
		firstname,
		lastname,
		weight,
		goalWeight,
		goal,
		sortedIndex,
		mealPlans = []
	},
	history,
	i
}) => {
	const formattedWeight = `${
		weight ? parseInt(weight).toFixed(0) + " lbs" : "None Set"
	} → ${goalWeight ? goalWeight + " lbs" : "None Set"}`;
	return (
		<section
			onClick={() =>
				history.push(`/client/${sortedIndex}/${firstname}-${lastname}`)
			}
			className="ClientCard-container"
		>
			<div>
				<ClientPhoto
					clientId={id}
					imageExtension={imageExtension}
					imageRotation={imageRotation}
				/>
			</div>
			<div>
				<h6>{firstname + " " + lastname}</h6>
				<div>
					<span>{formattedWeight}</span>
				</div>
			</div>
			<span className="d-none d-md-block ml-sm-5">
				{mealPlans.length} Meal Plan{mealPlans.length !== 1 ? "s" : ""}
			</span>
			<span className="d-none d-md-block ml-sm-5">{goal}</span>
		</section>
	);
};
export default withRouter(ClientCard);
