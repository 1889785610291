import React from "react";
import "./DropDownInput.css";

const DropDownInput = ({ name, label, options, onChange, value, className }) => {
	return (
		<div className={`drop-down-input ${className || ""}`}>
			<label>{label}</label>
      <select name={name} value={value} onChange={onChange}>
				{options.map((o, i) => (
          <option key={i}>{o.value}</option>
				))}
      </select>
		</div>
	);
};

export default DropDownInput;
