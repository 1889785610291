import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import "./Toast.css";

const Toast = ({ type, children }) => {
	let icon = "";
	switch (type) {
		case "success":
			icon = <FontAwesomeIcon icon={faCheckCircle} />;
			break;
		case "error":
			icon = <FontAwesomeIcon icon={faExclamationCircle} />;
			break;
		default:
	}
	return (
		<div className="text-center fw-700">
			{icon} {children}
		</div>
	);
};

export default Toast;
