import React, { useState } from "react";
import Button from "../Button/Button";
import "./ImageInput.css";

const INITIAL_STATE = (value = null, extension = "", rotation = 0) => ({
	image: value,
	extension,
	rotation: parseInt(rotation)
});

const ImageInput = ({
	name = "imageInput",
	label,
	value,
	extension,
	rotation,
	onChange,
	className
}) => {
	const [state, setState] = useState(
		INITIAL_STATE(value, extension, rotation)
	);
	const onImageChange = e => {
		const reader = new FileReader();
		if (e.target.files && e.target.files.length) {
			const file = e.target.files[0];
			const splitFileName = file.name.split(".");
			const newExtension = splitFileName[splitFileName.length - 1];
			reader.onload = upload => {
				const encodedImage = upload.target.result;
				setState({
					...state,
					image: encodedImage,
					extension: newExtension
				});
				onChange({
					image: encodedImage,
					extension: newExtension,
					rotation: state.rotation.toString()
				});
			};
			reader.readAsDataURL(file);
		}
	};
	const onButtonClick = event => {
		event.preventDefault();
		const fileInput = document.querySelectorAll(
			`input[type="file"][name="${name}"]`
		);
		if (fileInput && fileInput.length) fileInput[0].click();
		else console.error("could not find file input: ", name);
	};
	const onRotateClick = event => {
		event.preventDefault();
		const newRotation = (state.rotation + 1) % 4;
		setState({
			...state,
			rotation: newRotation
		});
		onChange({
			image: state.image,
			extension: state.extension,
			rotation: newRotation.toString()
		});
	};
	let selectedImageElement = "";
	if (state.image) {
		selectedImageElement = (
			<div
				className={`image-preview rotate-${state.rotation}`}
				style={{ backgroundImage: `url(${state.image})` }}
			/>
		);
	}
	let labelElement = "";
	if (label) {
		labelElement = <label>{label}</label>;
	}
	return (
		<div className={`ImageInput-container ${className || ""}`}>
			{labelElement}
			<input type="file" name={name} onChange={onImageChange} />
			{selectedImageElement}
			<footer className="row">
				<div className="col-6 col-md-12">
					<Button
						type="primary"
						label="Select Image"
						onClick={onButtonClick}
						fullWidth
					/>
				</div>
				<div className="col-6 col-md-12 mt-md-3">
					<Button
						disabled={!state.image}
						type="secondary"
						label="Rotate Image"
						onClick={onRotateClick}
						fullWidth
					/>
				</div>
			</footer>
		</div>
	);
};

export default ImageInput;
