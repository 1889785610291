import React, { useState } from "react";
import "./CreateClient.css";
import Input from "../../NewElements/Inputs/Input";
import Button from "../../Common/Button/Button";
import BackButton from "../../Common/Button/BackButton";
import SelectOneInput from "../../Common/SelectOneInput/SelectOneInput";
import ImageInput from "../../Common/ImageInput/ImageInput";
import Overlay from "../../Common/Overlay/Overlay";

import withAuth from "../../Auth/WithAuth";
import { saveClient } from "../../apiCalls";

const INITIAL_STATE = {
	clientEmail: "",
	firstname: "",
	lastname: "",
	gender: "",
	month: "01",
	day: "01",
	year: "2000",
	weight: "0",
	goalWeight: "0",
	height: "0",
	imageExtension: "missing",
	imageRotation: "0",
	photo: ""
};

const CreateClient = ({
	history,
	userInfo,
	updateUser,
	savingClient,
	clientIsSaving
}) => {
	const [state, setState] = useState(INITIAL_STATE);
	const {
		clientEmail,
		firstname,
		lastname,
		month,
		day,
		year,
		weight,
		goalWeight,
		height,
		gender,
		photo,
		imageExtension,
		imageRotation
	} = state;
	const updateState = ({ target: { value, name } }) => {
		setState({ ...state, [name]: value });
	};
	const onImageChange = ({ image, extension, rotation }) =>
		setState({
			...state,
			photo: image,
			imageExtension: extension,
			imageRotation: rotation
		});
	const onSave = async e => {
		e.preventDefault();
		savingClient();
		const savedClientId = await saveClient(state, userInfo, history);
		if (savedClientId) {
			const clients = [
				...userInfo.clients,
				{ ...state, id: savedClientId, mealCount: "3", meals: [] }
			];
			updateUser({
				...userInfo,
				clients
			});
			history.push("/client-list");
		}
	};
	let birthdate = `${year}-${month}-${day}`;
	return (
		<div className="CreateClient-container switch-route">
			<header className="bg-white px-2 py-3 text-center">
				<h4>
					<strong>Add New Client</strong>
				</h4>
			</header>
			<main style={{ paddingBottom: 65 }}>
				<form>
					<div className="row">
						<div className="col-12 col-md-6">
							<ImageInput
								name="clientPhoto"
								label="Client Photo"
								value={photo}
								extension={imageExtension}
								rotation={imageRotation}
								onChange={onImageChange}
							/>
						</div>
						<div className="col-12 col-md-6">
							<Input
								type="email"
								name="clientEmail"
								value={clientEmail}
								onChange={updateState}
								label="Email"
							/>
							<Input
								type="text"
								name="firstname"
								value={firstname}
								onChange={updateState}
								label="First Name"
							/>
							<Input
								type="text"
								name="lastname"
								value={lastname}
								onChange={updateState}
								label="Last Name"
							/>
							<SelectOneInput
								label="Gender"
								options={[
									{ label: "Female", value: "Female" },
									{ label: "Male", value: "Male" }
								]}
								value={gender}
								onSelect={value =>
									updateState({
										target: { name: "gender", value }
									})
								}
							/>
							<Input
								type="date"
								name="birthdate"
								value={birthdate}
								onChange={event => {
									const split = event.target.value.split("-");
									const newYear = split[0];
									const newMonth = split[1];
									const newDay = split[2];
									setState({
										...state,
										year: newYear,
										month: newMonth,
										day: newDay
									});
								}}
								label="Birthdate"
							/>
							<div style={{ display: "flex" }}>
								<Input
									type="number"
									name="weight"
									value={weight}
									onChange={updateState}
									label="Weight (lb)"
									min="0"
									step="0.5"
									style={{
										marginTop: 20,
										marginBottom: 20,
										marginRight: 20
									}}
								/>
								<Input
									type="number"
									name="goalWeight"
									value={goalWeight}
									onChange={updateState}
									label="Goal Weight (lb)"
									min="0"
									step="0.5"
								/>
							</div>
							<Input
								type="number"
								name="height"
								value={height}
								onChange={updateState}
								label="Height (inches)"
								min="0"
								step="0.25"
							/>
						</div>
					</div>
				</form>
			</main>
			<footer className="footer-action-btns">
				<BackButton onClick={() => history.push("/")} />
				<Button
					type="primary"
					label={clientIsSaving ? "Saving Client" : "Save Client"}
					disabled={
						!userInfo || !firstname || !gender || clientIsSaving
					}
					onClick={onSave}
				/>
			</footer>
			{clientIsSaving && (
				<Overlay>
					<strong>Saving</strong>
				</Overlay>
			)}
		</div>
	);
};

export default withAuth(CreateClient);
