import React from "react";
import Button from "../../../Common/Button/Button";
import BackButton from "../../../Common/Button/BackButton";
import "./ClientProfFooter.css";

const ClientProfFooter = ({
	readOnly,
	history,
	calcBtn,
	toggleEdit,
	onSave,
	cancel
}) => {
	let footer;
	if (readOnly) {
		footer = (
			<>
				<BackButton onClick={() => history.push("/client-list")} />
				{calcBtn}
				<Button label="Edit" type="primary" onClick={toggleEdit} />
			</>
		);
	} else {
		footer = (
			<>
				<BackButton onClick={cancel} />
				{calcBtn}
				<Button label="Save" type="primary" onClick={onSave} />
			</>
		);
	}
	return (
		<footer className="ClientProfFooter-container footer-action-btns">
			{footer}
		</footer>
	);
};
export default ClientProfFooter;
