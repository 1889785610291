import React, { useState, useEffect } from "react";

import "./ClientMealPlans.css";

import { updateClient } from "../../../apiCalls";
import { areArraysEqual } from "../../../Utils";
import Input from "../../../NewElements/Inputs/Input";
import Button from "../../../Common/Button/Button";
import BackButton from "../../../Common/Button/BackButton";
import withAuth from "../../../Auth/WithAuth";
import ClientMealPlanCard from "./ClientMealPlanCard";

const DEFAULT_MEAL_PLAN = {
	name: "Untitled Meal Plan",
	meals: new Array(3)
		.fill({ foods: [] })
		.map((o, i) => ({ ...o, name: `Meal ${i + 1}` }))
};

const ClientMealPlans = ({
	updateClientInRedux,
	userInfo,
	match: { params },
	history,
	location: { pathname }
}) => {
	const [client, setClient] = useState(null),
		[targetCalories, setTargetCalories] = useState(0),
		[mealPlans, setMealPlans] = useState([]),
		index = params.i;

	useEffect(() => {
		if (!client && userInfo) {
			const selectedClient = userInfo.clients[index];
			setClient(selectedClient);
			setTargetCalories(selectedClient.target);
			const isFirstMealPlan =
				mealPlans.length === 0 &&
				selectedClient.mealPlans &&
				selectedClient.mealPlans.length === 0;
			if (isFirstMealPlan) {
				setMealPlans([DEFAULT_MEAL_PLAN]);
			} else if (
				selectedClient.mealPlans &&
				!areArraysEqual(selectedClient.mealPlans, mealPlans)
			) {
				setMealPlans(selectedClient.mealPlans);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client, userInfo, index]);

	const addMealPlan = async () => {
		const { email } = userInfo;
		const appendedMealPlans = [...mealPlans, DEFAULT_MEAL_PLAN];
		client.mealPlans = appendedMealPlans;
		setMealPlans(appendedMealPlans);
		updateClientInRedux(client, userInfo, index);
		updateClient({ ...client }, { email }, index);
	};
	let name = "Loading";
	if (client) {
		name = `${client.firstname}'s Meal Plans`;
	}

	return (
		<div className="ClientMealPlans-container switch-route">
			<header className="py-3 bg-white text-center px-2">
				<h4>
					<strong>{name}</strong>
				</h4>
			</header>
			<main>
				<div className="row">
					<div className="col-6">
						<Input
							type="number"
							name="calories"
							value={targetCalories || 0}
							label="Target Cals"
							min="0"
							readOnly
						/>
					</div>
				</div>
				<div className="pb-3">
					{mealPlans.map((mealPlan, i) => (
						<ClientMealPlanCard
							key={i}
							index={i}
							history={history}
							pathname={pathname}
							mealPlan={mealPlan}
						/>
					))}
				</div>
			</main>
			<footer className="footer-action-btns">
				<BackButton
					onClick={() =>
						history.push(
							`/client/${index}/${client.firstname}-${client.lastname}`
						)
					}
				/>
				<Button
					label="Add Meal Plan"
					type="primary"
					onClick={addMealPlan}
				/>
			</footer>
		</div>
	);
};
export default withAuth(ClientMealPlans);
