import React from "react";
import "./Drawer.css";
import Button from "../Button/Button";
import BackButton from "../Button/BackButton";

const Drawer = ({
	title,
	children,
	show = false,
	toggle,
	save,
	user,
	saveDisabled
}) => {
	return (
		<div
			className="Drawer-container"
			style={{
				bottom: show ? 0 : "-100vh",
				opacity: show ? 1 : 0
			}}
		>
			<header className="bg-white px-2 py-3 text-center">
				<h4>
					<strong>{title}</strong>
				</h4>
			</header>
			<main className="p-4">{children}</main>
			<footer className="pb-2 px-2">
				<BackButton onClick={toggle} />
				<Button
					onClick={save}
					type="primary"
					disabled={!user || saveDisabled}
					label="Save"
				/>
			</footer>
		</div>
	);
};
export default Drawer;
