import React from "react";
// import "./Table.css";

const Table = ({ headers = [], rows = [], className = "" }) => {
	return (
		<div className={`food-table-container ${className}`}>
			<table className="table table-striped">
				<thead>
					<tr>{headers}</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		</div>
	);
};
export default Table;
