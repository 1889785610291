import React, { useState } from "react";
import "./Login.css";

import { toast } from "react-toastify";
import Input from "../NewElements/Inputs/Input";
import Button from "../Common/Button/Button";
import { createUser, getUser } from "../apiCalls/index";
import { authenticate, pswdReset } from "../apiCalls/auth";
import {
	getCachedFoodLibrary,
	updateFoodLibraryCache
} from "../../redux/cache";
import withAuth from "../Auth/WithAuth";

const UNKNOWN_AUTH_ERROR =
	"An error occurred. Please try again later or contact support.";
const AUTH_ERROR_MESSAGE_MAPPING = {
	EMAIL_EXISTS:
		"This email is already in use. Please use a different email or log in.",
	TOO_MANY_ATTEMPTS_TRY_LATER:
		"There have been too many submissions from your device. Please try again later.",
	EMAIL_NOT_FOUND: "There is no account linked to this email address.",
	INVALID_PASSWORD: "The password you've entered is incorrect.",
	INVALID_EMAIL: "There is no account linked to this email address."
};

const INIT_STATE = {
	email: "",
	password: "",
	firstname: "",
	lastname: "",
	trainer: true
};

const Login = ({ history, updateUser, loadingFoodLib, updateFoodLib }) => {
	// state
	const [state, setState] = useState(INIT_STATE),
		[login, setLogin] = useState(true),
		[frgtPswd, setFrgtPswrd] = useState(false),
		{ loginS, signupS } = styles,
		btnLabel = login ? "Need an Account?" : "Have an Account?";
	// methods
	const updateState = ({ target: { value, name } }) => {
			setState(s => ({ ...s, [name]: value }));
		},
		updateAndNav = async auth => {
			getUser(auth.email, res => updateUser({ ...auth, ...res }));
			// Initialize food library on login
			const localFoodLibrary = await getCachedFoodLibrary();
			if (!localFoodLibrary) {
				loadingFoodLib();
				updateFoodLib(await updateFoodLibraryCache());
			} else {
				updateFoodLib(localFoodLibrary);
			}
			history.push(state.trainer ? "/client-list" : "/");
		},
		resetAndReload = async email => {
			try {
				const res = await pswdReset(email);
				if (res.error) {
					const mappedError =
						AUTH_ERROR_MESSAGE_MAPPING[res.error.message];
					if (mappedError) {
						toast.error(mappedError);
					} else {
						console.error("Unmapped error occurred:", res.error);
						toast.error(UNKNOWN_AUTH_ERROR);
					}
				} else {
					toast.success("Success");
				}
			} catch (er) {
				const mappedError =
					AUTH_ERROR_MESSAGE_MAPPING[er.response.data.error.message];
				if (mappedError) {
					toast.error(mappedError);
				} else {
					console.error("Unmapped error occurred:", er.response);
					toast.error(UNKNOWN_AUTH_ERROR);
				}
			}
		};
	// api
	const authCall = async type => {
		const { email, password, firstname, lastname, trainer } = state;
		try {
			// setLoading(true);
			const res = await authenticate(
				email,
				password,
				type,
				`${firstname} ${lastname}`
			);
			if (res) {
				// Got an error from authenticating
				if (res.error) {
					const mappedError =
						AUTH_ERROR_MESSAGE_MAPPING[res.error.message];
					if (mappedError) {
						toast.error(mappedError);
					} else {
						console.error("Unmapped error occurred:", res.error);
						toast.error(UNKNOWN_AUTH_ERROR);
					}
				} else {
					if (type === "signup") {
						const res2 = await createUser(
							email,
							firstname,
							lastname,
							trainer
						);
						if (res2) {
							updateAndNav(res);
						}
					} else {
						updateAndNav(res);
					}
				}
			} else {
				// Did not get a response from authenticating
				toast.error(UNKNOWN_AUTH_ERROR);
			}
		} catch (err) {
			console.error("Error occurred:", err);
			toast.error(UNKNOWN_AUTH_ERROR);
		}
	};
	return (
		<div className="Login-container">
			<div style={login ? loginS(2, 0, 1) : loginS(1, 180, 0)}>
				<h2>Log In</h2>
				<Form
					login
					state={state}
					updateState={updateState}
					authCall={authCall}
					pswdReset={resetAndReload}
					frgtPswd={frgtPswd}
				>
					{login && (
						<>
							{!frgtPswd && (
								<div style={{ display: "flex", height: 40 }}>
									<Button
										label="Forgot Password"
										className="frgt-pswd-btn"
										onClick={e => {
											e.preventDefault();
											setFrgtPswrd(true);
										}}
									/>
								</div>
							)}
							<input
								type="submit"
								value={!frgtPswd ? "Log In" : "Send Email"}
							/>
							<Button
								className="tgle-signup-login-btn"
								label={!frgtPswd ? btnLabel : "Cancel"}
								fullWidth
								onClick={e => {
									e.preventDefault();
									!frgtPswd
										? setLogin(l => !l)
										: setFrgtPswrd(false);
								}}
							/>
						</>
					)}
				</Form>
			</div>
			<div style={login ? signupS(1, 180, 0) : signupS(2, 360, 1)}>
				<h2>Sign Up</h2>
				<Form
					state={state}
					updateState={updateState}
					authCall={authCall}
				>
					{!login && (
						<>
							<input type="submit" value="Get Started" />
							<Button
								className="tgle-signup-login-btn"
								label={btnLabel}
								fullWidth
								onClick={e => {
									e.preventDefault();
									setLogin(l => !l);
								}}
							/>
						</>
					)}
				</Form>
			</div>
		</div>
	);
};
const styles = {
	loginS: (z, r, o) => ({
		transform: `translateX(50%) rotateY(${r}deg)`,
		zIndex: z,
		opacity: o
	}),
	signupS: (z, r, o) => ({
		transform: `translateX(-50%) rotateY(${r}deg)`,
		zIndex: z,
		opacity: o
	})
};
export default withAuth(Login);

const Form = ({
	state: { email, password, firstname, lastname, trainer },
	updateState,
	login,
	authCall,
	frgtPswd,
	pswdReset,
	children
}) => {
	const [showPswd, setShowPswd] = useState(false);
	const onSubmit = e => {
			e.preventDefault();
			!frgtPswd ? authCall(!login && "signup") : pswdReset(email);
		},
		updateTrainer = value => {
			updateState({ target: { name: "trainer", value } });
		},
		isTrainer = b =>
			b
				? {
						backgroundColor: "#007bff",
						color: "white",
						transition: "250ms"
				  }
				: {};

	return (
		<form onSubmit={onSubmit}>
			<Input
				type="email"
				name="email"
				value={email}
				onChange={updateState}
				label="Email"
				required
			/>
			{!frgtPswd && (
				<div className="password-field">
					<Input
						type={showPswd ? "text" : "password"}
						name="password"
						value={password}
						onChange={updateState}
						label="Password"
						minLength="8"
						required
					/>
					<div
						title="Show Password"
						className="show-pswd-btn"
						onClick={e => {
							e.preventDefault();
							setShowPswd(s => !s);
						}}
					>
						{showPswd ? "Hide" : "Show"}
					</div>
				</div>
			)}
			{!login && (
				<>
					<Input
						type="text"
						name="firstname"
						value={firstname}
						onChange={updateState}
						label="First Name"
						required={!login}
					/>
					<Input
						type="text"
						name="lastname"
						value={lastname}
						onChange={updateState}
						label="Last Name"
						required={!login}
					/>
					<div className="trainer-check">
						<div
							style={isTrainer(trainer)}
							onClick={() => updateTrainer(true)}
						>
							Trainer
						</div>
						<div
							style={isTrainer(!trainer)}
							onClick={() => updateTrainer(false)}
						>
							Client
						</div>
					</div>
				</>
			)}
			{children}
		</form>
	);
};
