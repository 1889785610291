import React from "react";
import "./MultiAddInput.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const MultiAddInputItem = ({ value, index, onRemove, onUpdate, readonly }) => {
	return (
		<li>
			<textarea
				value={value}
				disabled={readonly}
				onChange={e => onUpdate(index, e.target.value)}
			/>
			{readonly ? (
				""
			) : (
				<div className="remove-btn" onClick={() => onRemove(index)}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			)}
		</li>
	);
};

const MultiAddInput = ({
	items = [],
	label,
	itemLabel,
	onAdd,
	onRemove,
	onUpdate,
	style,
	readonly
}) => {
	const itemElements =
		items &&
		items.map((item, i) => (
			<MultiAddInputItem
				key={`note-${i}`}
				value={item}
				index={i}
				readonly={readonly}
				onUpdate={onUpdate}
				onRemove={onRemove}
			/>
		));
	return (
		<div className="MultiAddInput-container" style={style}>
			<header>
				<label>{label}</label>
				{!readonly ? (
					<button onClick={() => onAdd()}>Add {itemLabel}</button>
				) : (
					""
				)}
			</header>
			<ul>
				{itemElements && itemElements.length ? (
					itemElements
				) : (
					<li>You have no {label.toLowerCase()}</li>
				)}
			</ul>
		</div>
	);
};
export default MultiAddInput;
