import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./Search.css";

const Search = ({ val, onChange, onSearch }) => {
	return (
		<div className="Search-container position-relative">
			<input
				type="text"
				id="inputSearch"
				className="form-control"
				placeholder="Search"
				value={val}
				onChange={onChange}
				// required=""
				// autoFocus=""
			/>
			<button
				onClick={onSearch}
				type="button"
				className="btn btn-sm btn-primary"
			>
				<FontAwesomeIcon icon={faSearch} />
			</button>
		</div>
	);
};
export default Search;
