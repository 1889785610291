import React from "react";

export default ({ plannedUsage, selected, onSelect }) => {
	const style = v =>
			selected === v
				? { border: "1px solid #007bff" }
				: { border: "1px solid transparent" },
		select = v => () => onSelect(v);
	return (
		<div className="row mt-3">
			<div className="col-3 pr-0">
				<DataCard
					label="Protein"
					style={style("usedP")}
					select={select("usedP")}
					usage={plannedUsage.usedP}
				/>
			</div>
			<div className="col-3 pr-1 pl-3">
				<DataCard
					label="Carbs"
					style={style("usedC")}
					select={select("usedC")}
					usage={plannedUsage.usedC}
				/>
			</div>
			<div className="col-3 pl-2 pr-3">
				<DataCard
					label="Fiber"
					style={style("usedFiber")}
					usage={plannedUsage.usedFiber}
				/>
			</div>
			<div className="col-3 pl-0">
				<DataCard
					label="Fats"
					style={style("usedF")}
					select={select("usedF")}
					usage={plannedUsage.usedF}
				/>
			</div>
		</div>
	);
};

const DataCard = ({ label, usage, select = () => {}, style }) => (
	<section className="data-card" style={style} onClick={select}>
		<header>{label}</header>
		<main>{usage.toFixed(1)}</main>
	</section>
);
