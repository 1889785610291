export function activityOptions() {
	return [
		"Sedentary (little or no exercise)",
		"Lightly active (light exercise/sports 1-3 days/week)",
		"Moderately active (moderate exercise/sports 3-5 days/week)",
		"Very active (hard exercise/sports 6-7 days/week)",
		"Extra active (very hard exercise/sports & physical job or 2x training)"
	];
}
export function goalOptions() {
	return [
		"Lose 2 lb/week",
		"Lose 1.5 lb/week",
		"Lose 1 lb/week",
		"Lose 0.5 lb/week",
		"Maintain Weight",
		"Gain 0.5 lb/week",
		"Gain 1 lb/week",
		"Gain 1.5 lb/week",
		"Gain 2 lb/week"
	];
}
