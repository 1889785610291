import React, { useState } from "react";

export const Rows = ({
	name,
	calories,
	p,
	c,
	fiber,
	f,
	serving,
	servingType,
	onClick
}) => {
	const [active, setActive] = useState(false),
		clicked = () => {
			setActive(!active);
			onClick();
		};
	return (
		<tr onClick={clicked}>
			<td>{name}</td>
			<td>{calories}</td>
			<td>{p}</td>
			<td>{c}</td>
			<td>{fiber}</td>
			<td>{f}</td>
			<td>
				{serving} {servingType}
			</td>
		</tr>
	);
};
