export const utils = {
	months: [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	],
	convertToMonth: str => {
		const num = parseInt(utils.trimNumString(str)) - 1;
		return utils.months[num];
	},
	trimNumString: str => {
		if (str[0] === "0") return str[1];
		else return str;
	}
};
