import React from "react";
import MacroRow from "./MacroRow";
import MacroProgress from "./MacroProgress/MacroProgress";
import PTCals from "./PTCals";

export default ({
	plannedUsage,
	targetCalories = 1,
	macros = {
		protein: 40,
		carbs: 40,
		fats: 20
	}
}) => {
	const [selected, setSelected] = React.useState("usedP");
	let statusClass = "";
	let calorieTargetPercent = plannedUsage.usedCals / targetCalories;
	if (calorieTargetPercent < 0.7 || calorieTargetPercent > 1.3) {
		statusClass = "below-70";
	} else if (calorieTargetPercent < 0.9 || calorieTargetPercent > 1.1) {
		statusClass = "below-90";
	}
	return (
		<section className="mt-3">
			<PTCals
				statusClass={statusClass}
				plannedUsage={plannedUsage}
				targetCalories={targetCalories}
			/>
			<MacroRow
				plannedUsage={plannedUsage}
				selected={selected}
				onSelect={setSelected}
			/>
			<MacroProgress
				macros={macros}
				selected={selected}
				targetCalories={targetCalories}
				plannedUsage={plannedUsage}
			/>
		</section>
	);
};
