import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBook,
	faUsers,
	faUserPlus,
	faUser
} from "@fortawesome/free-solid-svg-icons";
import "./Landing.css";

import TrainerLanding from "./TrainerLanding";
import ClientSideLanding from "./ClientSideLanding";

const landingTextTrainer = (
	<>
		With <strong>MealPlanner</strong>, you can manage client meals and track
		client progress all in one place.
	</>
);
const landingTextClient = (
	<>
		With <strong>MealPlanner</strong>, you can find trainers who can plan
		meals and manage your progress.
	</>
);

const Landing = ({ userInfo }) => {
	let welcome = "Welcome to ",
		landingText = <strong>MealPlanner</strong>;
	if (userInfo && userInfo.firstname) {
		welcome = `Hey, ${userInfo.firstname}! `;
		if (userInfo.trainer) landingText = landingTextTrainer;
		else landingText = landingTextClient;
	}
	return (
		<div className="Landing-container bg-offwhite">
			<header className="bg-white px-5 py-4 text-darken-75 fw-600">
				{welcome}
				{landingText}
			</header>
			<section className="menu-section even-column">
				{!userInfo ? null : userInfo.trainer ? (
					<TrainerLanding
						Link={Link}
						FontAwesomeIcon={FontAwesomeIcon}
						faUserPlus={faUserPlus}
						faUsers={faUsers}
						faBook={faBook}
					/>
				) : (
					<ClientSideLanding
						Link={Link}
						FontAwesomeIcon={FontAwesomeIcon}
						faUserPlus={faUserPlus}
						faUser={faUser}
						faBook={faBook}
					/>
				)}
			</section>
		</div>
	);
};

function mapStateToProps({ userInfo }) {
	return {
		userInfo
	};
}

export default connect(mapStateToProps)(Landing);
