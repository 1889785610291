import React from "react";
import { Route } from "react-router-dom";

const ClientRoutes = () => (
	<>
		<Route
			exact
			path="/my-plan"
			render={() => <div className="switch-route">My Plan</div>}
		/>
		<Route
			exact
			path="/my-profile"
			render={() => <div className="switch-route">My Profile</div>}
		/>
	</>
);

export default ClientRoutes;
