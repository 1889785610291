// "High Protein", "High Carb", "High Fat", "Dairy", "Meat", "Gluten Free";
import React, { useState } from "react";
import { toast } from "react-toastify";

import { updateFoodLibraryCache } from "../../redux/cache";
import Drawer from "../Common/Drawer/Drawer";
import Input from "../NewElements/Inputs/Input";
import Toast from "../Common/Toast/Toast";
import { postFoodToLibrary } from "../apiCalls";
import { FORM_VALUES } from "./utils";

const AddNewFoodDrawer = ({
	show,
	toggleShow,
	userInfo,
	loadingFoodLib,
	updateFoodLib,
	foodLibrary
}) => {
	const [formState, setFormState] = useState(FORM_VALUES);
	const { name, calories, p, c, fiber, f, serving, servingType } = formState;
	const updateVals = ({ target: { name, value } }) => {
		setFormState({ ...formState, [name]: value });
	};
	const checkLibBeforePosting = () => {
		return foodLibrary.filter(el => el.name === formState.name);
	};
	const updateData = () => {
		const check = checkLibBeforePosting();
		if (!check || !check.length) {
			loadingFoodLib();
			postFoodToLibrary(formState, () => {
				updateFoodLibraryCache().then(updateFoodLib);
			});
			setFormState(FORM_VALUES);
			toggleShow();
		} else {
			toast.error(
				<Toast>
					Looks like a food with that name has already been entered.
					If you still want to add this food, try changing the name.
				</Toast>
			);
		}
	};
	return (
		<Drawer
			title="Add New Food"
			show={show}
			toggle={toggleShow}
			save={updateData}
			user={userInfo}
			saveDisabled={!name}
		>
			<Input
				type="text"
				name="name"
				value={name}
				onChange={updateVals}
				label="Name"
			/>
			<Input
				type="number"
				name="calories"
				value={calories}
				onChange={updateVals}
				min="0"
				step="0.5"
				label="Calories"
			/>
			<Input
				type="number"
				name="p"
				value={p}
				onChange={updateVals}
				min="0"
				step="0.5"
				label="Protein (g)"
			/>
			<Input
				type="number"
				name="c"
				value={c}
				onChange={updateVals}
				min="0"
				step="0.5"
				label="Carbohydrates (g)"
			/>
			<Input
				type="number"
				name="fiber"
				value={fiber}
				onChange={updateVals}
				min="0"
				step="0.5"
				label="Fiber (g)"
			/>
			<Input
				type="number"
				name="f"
				value={f}
				onChange={updateVals}
				min="0"
				step="0.5"
				label="Fats (g)"
			/>
			<Input
				type="text"
				name="serving"
				value={serving}
				onChange={updateVals}
				label="Serving Size (1, 1/2, etc.)"
			/>
			<Input
				type="text"
				name="servingType"
				value={servingType}
				onChange={updateVals}
				label="Serving Unit (bar, oz., g, etc.)"
			/>
		</Drawer>
	);
};
export default AddNewFoodDrawer;
