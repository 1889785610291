import React, { useState, useEffect } from "react";
import "./FindPeople.css";

import Search from "../Common/Search/Search";
import Button from "../Common/Button/Button";
import PeopleCard from "./PeopleCard/PeopleCard";

import { getAllUsers } from "../apiCalls";
import withAuth from "../Auth/WithAuth";

const FindPeople = ({ history, peopleList, updatePeopleList }) => {
	const [people, setPeople] = useState(null),
		[search, setSearch] = useState(""),
		[trainerFltr, setTrainerFltr] = useState(false),
		[clientFltr, setClientFltr] = useState(false),
		[alphFltr, setAlphFltr] = useState(false),
		[filteredPeople, setFilteredPeople] = useState([]);
	useEffect(() => {
		const get = async () => {
			getAllUsers(res => {
				setPeople(res);
				setFilteredPeople(res);
				updatePeopleList(res);
			});
		};
		if (!people && !peopleList.length) get();
		else if (!people && peopleList.length > 0) {
			setPeople(peopleList);
			setFilteredPeople(peopleList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [people, peopleList]);

	const newSearch = () => {
			const newData = people.filter(
				el =>
					el.firstname.toLowerCase().includes(search.toLowerCase()) ||
					el.lastname.toLowerCase().includes(search.toLowerCase())
			);
			setFilteredPeople(newData);
		},
		updateSearch = ({ target: { value } }) => {
			setSearch(value);
		};

	const peopleMap =
		people &&
		filteredPeople &&
		filteredPeople.map((v, i) => (
			<PeopleCard key={i} v={v} history={history} />
		));

	return (
		<div className="FindPeople-container switch-route">
			<header
				style={{ textAlign: "center" }}
				className="bg-white px-5 py-4 text-darken-75 fw-600"
			>
				<b>Find People</b>
			</header>
			<div>
				<Search
					val={search}
					onChange={updateSearch}
					onSearch={newSearch}
				/>
				<div className="ppl-search-filters">
					<Button
						label={<b>Trainers</b>}
						type={trainerFltr ? "primary" : "secondary"}
						className="ppl-filter-btn"
						fullWidth
						onClick={() => setTrainerFltr(b => !b)}
					/>
					<Button
						label={<b>Clients</b>}
						type={clientFltr ? "primary" : "secondary"}
						className="ppl-filter-btn"
						fullWidth
						onClick={() => setClientFltr(b => !b)}
					/>
					<Button
						label={<b>A-Z</b>}
						type={alphFltr ? "primary" : "secondary"}
						className="ppl-filter-btn"
						fullWidth
						onClick={() => setAlphFltr(b => !b)}
					/>
				</div>
				<section>{peopleMap || <span>Loading People</span>}</section>
			</div>
		</div>
	);
};

export default withAuth(FindPeople);
