import React from "react";
import "./About.css";
import withAuth from "../Auth/WithAuth";

const About = ({ userInfo }) => {
	return (
		<div className="About-container switch-route">
			<div>About</div>
		</div>
	);
};

export default withAuth(About);
