import React, { useState } from "react";
import "./FoodLibrary.css";

import Search from "../Common/Search/Search";
import Table from "../Common/Table/Table";
import Button from "../Common/Button/Button";
import BackButton from "../Common/Button/BackButton";
import AddNewFoodDrawer from "./AddNewFoodDrawer";
import FoodCard from "./FoodCard";
import withAuth from "../Auth/WithAuth";

import { HEADERS, Rows } from "./utils";

const FoodLibrary = ({
	userInfo,
	history,
	foodLibrary,
	foodLibraryIsLoading,
	loadingFoodLib,
	updateFoodLib
}) => {
	const [show, setShow] = useState(false),
		[search, setSearch] = useState(""),
		[filteredData, setFilteredData] = useState([]),
		toggleShow = () => setShow(!show),
		newSearch = () => {
			const newData = foodLibrary.filter(el =>
				el.name.toLowerCase().includes(search.toLowerCase())
			);
			setFilteredData(newData);
		},
		updateSearch = ({ target: { value } }) => {
			setSearch(value);
		},
		[dataMap, filteredMap] = [
			foodLibrary &&
				foodLibrary.length &&
				foodLibrary.map((el, i) => (
					<Rows
						key={i}
						name={el.name}
						calories={el.calories}
						p={el.p}
						c={el.c}
						fiber={el.fiber || ""}
						f={el.f}
						serving={el.serving}
						servingType={el.servingType}
					/>
				)),
			!!filteredData.length &&
				filteredData.map((el, i) => (
					<Rows
						key={i}
						name={el.name}
						calories={el.calories}
						p={el.p}
						c={el.c}
						fiber={el.fiber || ""}
						f={el.f}
						serving={el.serving}
						servingType={el.servingType}
					/>
				))
		];
	const foodCards = (filteredData.length ? filteredData : foodLibrary)
		.sort((a, b) => {
			if (b.name > a.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		})
		.map((food, i) => <FoodCard key={i} {...food} />);
	return (
		<div
			style={{ position: "relative", overflow: "hidden" }}
			className="FoodLibrary-container switch-route"
		>
			<header className="bg-white px-2 py-3 text-center">
				<h4>
					<strong>Food Library</strong>
				</h4>
			</header>
			{foodLibraryIsLoading ? (
				<div
					className="d-flex flex-column justify-content-center text-center"
					style={{ height: "calc(100vh - 110px)" }}
				>
					Loading
				</div>
			) : (
				<>
					<main>
						<Search
							val={search}
							onChange={updateSearch}
							onSearch={newSearch}
						/>
						<div className="d-block d-md-none">{foodCards}</div>
						<div className="d-none d-md-block">
							<Table
								headers={HEADERS}
								rows={!filteredMap ? dataMap : filteredMap}
							/>
						</div>
					</main>
					<footer className="footer-action-btns">
						<BackButton onClick={() => history.push("/")} />
						{
							<Button
								onClick={toggleShow}
								type="primary"
								label="Add Food"
								disabled={!userInfo}
							/>
						}
					</footer>
					<AddNewFoodDrawer
						foodLibrary={foodLibrary}
						show={show}
						toggleShow={toggleShow}
						userInfo={userInfo}
						loadingFoodLib={loadingFoodLib}
						updateFoodLib={updateFoodLib}
					/>
				</>
			)}
		</div>
	);
};
export default withAuth(FoodLibrary);

// Name, calories, macros, serving size(cup or tablespoon), servings.
