import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import {
	loadingUser,
	notLoadingUser,
	updateUser,
	updateClientInRedux,
	savingClient,
	notSavingClient,
	deletingClient,
	doneDeletingClient,
	loadingFoodLib,
	updateFoodLib,
	loadingPeopleList,
	updatePeopleList
} from "../../redux/reducer";
import { toast } from "react-toastify";
// import Axios from "axios";

const withAuthHOC = Wrapped => props => <Wrapped toast={toast} {...props} />;

// Need to have this redirect to signup page if not signed in
// const withAuthHOC = Wrapped => props =>
// 	!props.userInfo ? <div>Need To signin</div> : <Wrapped {...props} />;

const mapStateToProps = ({
	userInfo,
	userIsLoading,
	clientIsSaving,
	userIsDeletingClient,
	foodLibrary,
	foodLibraryIsLoading,
	peopleList,
	peopleListIsLoading
}) => ({
	userInfo,
	userIsLoading,
	clientIsSaving,
	userIsDeletingClient,
	foodLibrary,
	foodLibraryIsLoading,
	peopleList,
	peopleListIsLoading
});

const withAuth = compose(
	withRouter,
	connect(mapStateToProps, {
		loadingUser,
		notLoadingUser,
		updateUser,
		savingClient,
		notSavingClient,
		deletingClient,
		doneDeletingClient,
		updateClientInRedux,
		loadingFoodLib,
		updateFoodLib,
		loadingPeopleList,
		updatePeopleList
	}),
	withAuthHOC
);

export default withAuth;
