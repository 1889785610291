/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./App.css";
import { ToastContainer, cssTransition } from "react-toastify";
import { Switch, Route, Redirect } from "react-router-dom";

// Routes
import TrainerRoutes from "./routes/TrainerRoutes";
import ClientRoutes from "./routes/ClientRoutes";

// Components
import NavBar from "./components/NavBar/NavBar";
import Landing from "./components/Landing/Landing";
import About from "./components/About/About";
import Account from "./components/Account/Account";
import Login from "./components/Login/Login";
import FindPeople from "./components/FindPeople/FindPeople";
import FPProfile from "./components/FindPeople/FPProfile/FPProfile";
//
import { getUser } from "./components/apiCalls";
import { getCachedFoodLibrary, updateFoodLibraryCache } from "./redux/cache";
import withAuth from "./components/Auth/WithAuth";

const Zoom = cssTransition({
	enter: "zoomIn",
	exit: "zoomOut",
	duration: 300
});

const App = ({
	notLoadingUser,
	loadingUser,
	updateUser,
	userIsLoading,
	userInfo,
	loadingFoodLib,
	updateFoodLib,
	history
}) => {
	useEffect(() => {
		const tryLogin = async () => {
			const userDataRes = await localStorage.getItem("uDa");
			if (!userDataRes) {
				notLoadingUser();
				return history.push("/login");
			}
			// Login user
			loadingUser();
			const user = JSON.parse(atob(userDataRes));
			const { email, idToken, id, expiresIn } = user,
				expiry = new Date(expiresIn);
			if (expiry <= new Date() || !idToken || !id || !email) {
				notLoadingUser();
				return history.push("/login");
			}
			getUser(user.email, res => updateUser({ ...user, ...res }));
			// Initialize food library on login
			const localFoodLibrary = await getCachedFoodLibrary();
			if (!localFoodLibrary) {
				loadingFoodLib();
				updateFoodLib(await updateFoodLibraryCache());
			} else {
				updateFoodLib(localFoodLibrary);
			}
		};
		tryLogin();
	}, []);
	// useEffect(() => {
	// 	if (!userInfo && localStorage["uDa"]) {
	// 		loadingUser();
	// 		const user = JSON.parse(atob(localStorage.getItem("uDa")));
	// 		getUser(user.email, res => updateUser({ ...user, ...res }));
	// 	}
	// }, [userInfo, updateUser]);
	// function toLocal() {
	// 	localStorage.setItem("body", `${btoa(JSON.stringify(userInfo))}`);
	// }
	// if (userInfo) {
	// 	toLocal();
	// 	console.log(JSON.parse(atob(localStorage.getItem("body"))));
	// }
	let isTrainer = false;
	if (userInfo && userInfo.trainer) {
		isTrainer = Boolean(userInfo.trainer);
	}
	return (
		<>
			<NavBar />
			{userIsLoading ? (
				<div className="loading-user">Loading</div>
			) : (
				<Switch>
					<Route exact path="/" component={Landing} />
					<Route path="/about" component={About} />
					<Route path="/my-account" component={Account} />
					<Route exact path="/find-people" component={FindPeople} />
					<Route
						exact
						path="/fp-view-profile/:name"
						component={FPProfile}
					/>
					<Route path="/login" component={Login} />
					{isTrainer && <TrainerRoutes />}
					{!isTrainer && <ClientRoutes />}
					<Redirect to="/" />
				</Switch>
			)}
			<ToastContainer
				transition={Zoom}
				closeButton={false}
				hideProgressBar
			/>
		</>
	);
};

export default withAuth(App);
