import React, { useState } from "react";
import "./MealModal.css";

import Button from "../../../../Common/Button/Button";
import BackButton from "../../../../Common/Button/BackButton";
import Search from "../../../../Common/Search/Search";
import Table from "../../../../Common/Table/Table";
import { Rows } from "../../../../Common/Rows/Rows";
import EditItem from "../EditItem/EditItem";
import ManualFoodEntry from "./ManualFoodEntry";

import { HEADERS, FORM_VALUES } from "../../../../FoodLibrary/utils";

const MealModal = ({
	foodLib,
	modalState,
	toggleFoodModal,
	showFoodModal,
	updateMeal
}) => {
	const [lookup, setLookup] = useState(modalState.lookup || false),
		[search, setSearch] = useState(""),
		[filteredData, setFilteredData] = useState([]),
		[state, setState] = useState(modalState.values || FORM_VALUES),
		{
			id,
			name,
			calories,
			p,
			c,
			fiber,
			f,
			serving,
			servingType,
			original = {}
		} = state,
		updateState = ({ target: { name, value } }) => {
			setState({ ...state, [name]: value });
		},
		updateSearch = ({ target: { value } }) => {
			setSearch(value);
		},
		newSearch = () => {
			const newData = foodLib.filter(el =>
				el.name.toLowerCase().includes(search.toLowerCase())
			);
			setFilteredData(newData);
		},
		addFood = food => {
			if (!food.id) {
				food.id = "manualEntry";
			}
			"edit" in modalState
				? updateMeal(food, modalState.index, NaN, modalState.edit)
				: updateMeal(food, modalState.index);
			toggleFoodModal(!showFoodModal);
		},
		rowClick = food => {
			if (!state.id) setState(food);
			else setState(FORM_VALUES);
		},
		[dataMap, filteredMap] = [
			!!foodLib.length &&
				foodLib.map((el, i) => (
					<Rows
						key={i}
						name={el.name}
						calories={el.calories}
						p={el.p}
						c={el.c}
						fiber={el.fiber}
						f={el.f}
						serving={el.serving}
						servingType={el.servingType}
						onClick={() => rowClick(el)}
					/>
				)),
			!!filteredData.length &&
				filteredData.map((el, i) => (
					<Rows
						key={i}
						name={el.name}
						calories={el.calories}
						p={el.p}
						c={el.c}
						fiber={el.fiber}
						f={el.f}
						serving={el.serving}
						servingType={el.servingType}
						onClick={() => rowClick(el)}
					/>
				))
		];
	let manualEntryButton = "";
	let foodEntryStyling = {};
	if (id) {
		foodEntryStyling = {
			height: 0,
			overflow: "hidden",
			padding: 0
		};
	} else if (lookup) {
		manualEntryButton = (
			<Button
				type="primary"
				className="manual-entry-toggle"
				onClick={() => setLookup(!lookup)}
				label="Manual Entry"
			/>
		);
	}

	return (
		<div className="MealModal-container">
			<header className="bg-white px-2 py-3 text-center">
				<h4 className="m-0">
					<strong>
						Add Food to{" "}
						{modalState.mealName || `Meal #${modalState.index + 1}`}
					</strong>
				</h4>
			</header>
			<main>
				<EditItem
					id={id}
					name={name}
					calories={original.calories || calories}
					p={original.p || p}
					c={original.c || c}
					fiber={original.fiber || fiber}
					f={original.f || f}
					serving={original.serving || serving}
					servingType={servingType}
					editMode={original && Object.keys(original).length > 0}
					original={original}
					cancel={() => setState(FORM_VALUES)}
					submit={addFood}
				/>
				{lookup ? (
					<div style={foodEntryStyling} className="lookup-food-entry">
						<Search
							val={search}
							onChange={updateSearch}
							onSearch={newSearch}
							btnWidth={"25%"}
						/>
						<Table
							headers={HEADERS}
							rows={!filteredMap ? dataMap : filteredMap}
						/>
					</div>
				) : (
					<ManualFoodEntry
						name={name}
						calories={calories}
						p={p}
						c={c}
						fiber={fiber}
						f={f}
						serving={serving}
						servingType={servingType}
						setLookup={setLookup}
						lookup={lookup}
						updateState={updateState}
						state={state}
						addFood={addFood}
					/>
				)}
				{lookup && (
					<div className="mm-btn-container">
						<BackButton
							onClick={() => toggleFoodModal(!showFoodModal)}
						/>
						{manualEntryButton}
					</div>
				)}
			</main>
		</div>
	);
};
export default MealModal;
