import Axios from "axios";
import { utils } from "./utils";

const API_URL_BASE = "https://2h2ymb7jbj.execute-api.us-east-1.amazonaws.com";
const HEADERS = {
	"Content-Type": "application/json"
};

// User
export const saveClient = async (body, userInfo, history) => {
	const newDay = utils.trimNumString(body.day),
		newMonth = utils.convertToMonth(body.month);
	body.day = newDay;
	body.month = newMonth;
	if (!body.clientEmail) body.clientEmail = "missing";
	if (body.height === "0") body.height = "69";
	if (body.weight === "0") body.weight = "100";
	if (body.goalWeight === "0") body.goalWeight = "100";
	const res = await Axios.put(`${API_URL_BASE}/v1/ntClients`, {
		headers: HEADERS,
		email: userInfo.email,
		...body
	});
	if (!res.data || res.data === "Failed") {
		console.error(res);
		return false;
	}
	return res.data;
};

export const updateClient = async (body, userInfo, index) => {
	const res = await Axios.put(`${API_URL_BASE}/v1/ntClients/updateClient`, {
		headers: HEADERS,
		email: userInfo.email,
		index,
		...body
	});
	if (!res.data || !res.data === "Success") {
		console.error(res);
		return false;
	}
	return true;
};

export function deleteClient(email, index, cb) {
	Axios.delete(
		`${API_URL_BASE}/v1/ntClients/updateClient?@=${email}&i=${index}`,
		{
			headers: HEADERS
		}
	)
		.then(res => res.data === "Success" && cb())
		.catch(err => console.error(err));
}

export function getUser(email, cb) {
	Axios.get(`${API_URL_BASE}/v1/ntUsers?unique=${email}`, {
		headers: HEADERS
	})
		.then(res => cb(res.data))
		.catch(err => console.error(err));
}

export const createUser = async (email, firstname, lastname, trainer) => {
	const res = await Axios.post(`${API_URL_BASE}/v1/ntUsers`, {
		// method: "POST",
		// mode: "no-cors", // no-cors, *cors, same-origin
		headers: HEADERS,
		email,
		firstname,
		lastname,
		trainer
	});
	if (!res) {
		throw new Error("Failed to create UserInfo");
	}
	const resData = await res.data;
	return resData;
};

export function sendClientEmail(body, cb) {
	Axios.post(`${API_URL_BASE}/v1/ntses`, {
		headers: HEADERS,
		...body
	})
		.then(res => cb(res))
		.catch(err => console.error(err));
}

// Food Lib
export const getFoodLibrary = () =>
	new Promise((res, rej) => {
		Axios.get(`${API_URL_BASE}/v1/ntfoodlibrary`, {
			headers: HEADERS
		})
			.then(response => res(response.data.Items))
			.catch(err => {
				console.error(err);
				rej(err);
			});
	});

export function postFoodToLibrary(body, cb) {
	Axios.post(`${API_URL_BASE}/v1/ntfoodlibrary`, {
		headers: HEADERS,
		...body
	})
		.then(res => {
			console.log(res);
			cb(res);
		})
		.catch(err => console.error(err));
}

// Find People
export function getAllUsers(cb) {
	Axios.get(`${API_URL_BASE}/v1/ntgetallusers`, {
		headers: HEADERS
	})
		.then(res => cb(res.data))
		.catch(err => console.error(err));
}
