/* eslint-disable no-extend-native */
import moment from "moment";
import { activityOptions } from "../Utils/options";
// import "./String.prototype.toNum";

const activityLevels = activityOptions();
const targetAmounts = ["2", "1.5", "1", "0.5"];

//////// Wizard/index.js
export function back(pathname, history) {
	const split = pathname.split("/");
	if (split.length > 2) {
		if (split[2].split("-")[1] === "1") {
			history.push("/calc");
		} else if (split[2].split("-")[1] !== "1") {
			const int = parseInt(split[2].split("-")[1]) - 1;
			history.push(`/calc/step-${int}`);
		}
	}
}

export function next(pathname, history) {
	const split = pathname.split("/");
	if (split.length === 2) history.push("/calc/step-1");
	else if (split.length > 2) {
		const int = parseInt(split[2].split("-")[1]) + 1;
		history.push(`/calc/step-${int}`);
	}
}

export function nextBtn(pathname) {
	return pathname.split("/").length < 3
		? "Start"
		: pathname.split("/")[2].split("-")[1] !== "4"
		? "Next"
		: "Finish";
}

export function progress(pathname) {
	const split = pathname.split("/");
	let int = 0;
	if (split.length > 2) {
		int = parseInt(split[2].split("-")[1]);
	}
	return int;
}
////////////

/////// Wizard/StepOne.js
export function getAge(bday) {
	return moment(bday)
		.fromNow(true)
		.split(" ")[0];
}

export function createStepOneState(clientState) {
	if (!clientState) {
		clientState = defaultStepOneState();
	}
	return {
		gender: clientState.gender,
		// weight: clientState.weight.toNum(),
		weight: parseFloat(clientState.weight),
		height: parseFloat(clientState.height),
		age: parseInt(getAge(clientState))
	};
}

function defaultStepOneState() {
	return {
		gender: "",
		weight: "",
		height: "",
		month: "",
		day: "",
		year: ""
	};
}
///////////////////

///////////// ClientProfile.js
const calcBMR = (gender, weight, height, age) => {
	if (gender === "Male" || gender === "male") {
		return 66 + 6.23 * weight + 12.7 * height - 6.8 * age;
	}
	return 655 + 4.35 * weight + 4.7 * height - 4.7 * age;
};

const calcTargetWeightCals = (bmr, activity, target) => {
	let activityValue = 0;
	let targetAdjustment = 0;
	let newTargetVal = target.split(" ")[1];
	switch (activity) {
		case activityLevels[1]:
			activityValue = 1.375;
			break;
		case activityLevels[2]:
			activityValue = 1.55;
			break;
		case activityLevels[3]:
			activityValue = 1.725;
			break;
		case activityLevels[4]:
			activityValue = 1.9;
			break;
		default:
			activityValue = 1.2;
			break;
	}
	switch (newTargetVal) {
		case targetAmounts[0]:
			targetAdjustment = 1000;
			break;
		case targetAmounts[1]:
			targetAdjustment = 750;
			break;
		case targetAmounts[2]:
			targetAdjustment = 500;
			break;
		case targetAmounts[3]:
			targetAdjustment = 250;
			break;
		default:
			targetAdjustment = 0;
			break;
	}
	targetAdjustment = target.includes("Gain")
		? targetAdjustment
		: target.includes("Lose")
		? -targetAdjustment
		: targetAdjustment;
	const totalTargetCals = (bmr * activityValue + targetAdjustment).toFixed(2);
	return totalTargetCals;
};

export const calcCals = client => {
	const bmr = calcBMR(
		client.gender,
		toInt(client.weight),
		toInt(client.height),
		toInt(client.age)
	);
	return {
		bmr: bmr.toFixed(2),
		target: calcTargetWeightCals(bmr, client.activity, client.goal)
	};
};

const toInt = val => parseInt(val);

////////////

export const areArraysEqual = (arr1, arr2) => {
	let bool = true;
	if (arr1 && arr2 && arr1.length === arr2.length) {
		arr1.forEach((v, i) => {
			if (v.id === arr2[i].id && v.calories === arr2[i].calories)
				bool = true;
			else bool = false;
		});
	} else bool = false;
	return bool;
};

//////////////

export const addMetrics = meals => {
	let obj = {
		usedCals: 0,
		usedP: 0,
		usedC: 0,
		usedF: 0,
		usedFiber: 0
	};
	meals.forEach(_ => {
		_.foods.forEach(el => {
			obj.usedCals += parseFloat(el.calories || 0);
			obj.usedP += parseFloat(el.p || 0);
			obj.usedC += parseFloat(el.c || 0);
			obj.usedF += parseFloat(el.f || 0);
			obj.usedFiber += parseFloat(el.fiber || 0);
		});
	});
	return obj;
};
