import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faUsers, faUserPlus } from "@fortawesome/free-solid-svg-icons";
// import './TrainerLanding.css';

const TrainerLanding = ({ Link }) => {
	return (
		<>
			{/*
			<Link to="/find-people">
				<FontAwesomeIcon icon={faSearch} className="mr-3" />
				<strong>Connect</strong>
			</Link>
			*/}
			<Link to="/create-new-client">
				<FontAwesomeIcon icon={faUserPlus} className="mr-3" />
				<strong>New Client</strong>
			</Link>
			<Link to="/client-list">
				<FontAwesomeIcon icon={faUsers} className="mr-3" />
				<strong>My Clients</strong>
			</Link>
			<Link to="/food-library">
				<FontAwesomeIcon icon={faBook} className="mr-3" />
				<strong>Food Library</strong>
			</Link>
		</>
	);
};

export default TrainerLanding;
