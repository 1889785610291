import config from "./config";
import Axios from "axios";

const BASE_URL = "https://identitytoolkit.googleapis.com/v1/accounts";

export const authenticate = async (email, password, type, name) => {
	const url =
		type === "signup"
			? `${BASE_URL}:signUp?key=${config.firebaseApiKey}`
			: `${BASE_URL}:signInWithPassword?key=${config.firebaseApiKey}`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			email,
			password,
			returnSecureToken: true
		})
	});
	let resData = await res.json();
	if (!res.ok) {
		return resData;
	}
	if (type === "signup" && resData.idToken && !resData.displayName) {
		updateFireBaseName(resData.idToken, name);
	}
	const { users } = await getFireBaseUserData(resData.idToken);
	const [FB_USER] = users;
	resData.emailVerified = FB_USER.emailVerified;
	saveDataToStorage(
		resData.email,
		resData.idToken,
		resData.localId,
		FB_USER.emailVerified,
		resData.displayName,
		makeExpiryDate(resData.expiresIn).toISOString()
	);
	return resData;
};

//* Save to Local Storage //
const saveDataToStorage = async (
	email,
	idToken,
	id,
	emailVerified,
	displayName,
	expiresIn
) => {
	localStorage.setItem(
		"uDa",
		`${btoa(
			JSON.stringify({
				email,
				idToken,
				id,
				emailVerified,
				displayName,
				expiresIn
			})
		)}`
	);
};

const makeExpiryDate = ttl =>
	new Date(new Date().getTime() + parseInt(ttl) * 1000 * 24); //? ttl=3600 seconds (3600 * 1000 = 1 hour in mS * 24 = 24 hours in mS)

export const logout = cb => {
	localStorage.removeItem("uDa");
	cb();
};

export const pswdReset = async email => {
	const res = await Axios.post(
		`${BASE_URL}:sendOobCode?key=${config.firebaseApiKey}`,
		{
			requestType: "PASSWORD_RESET",
			email
		}
	);
	return await res.data;
};

export const pswdChange = async (idToken, password) => {
	const res = await Axios.post(
		`${BASE_URL}:update?key=${config.firebaseApiKey}`,
		{
			idToken,
			password,
			returnSecureToken: true
		}
	);
	return await res.data;
};

export const getFireBaseUserData = async idToken => {
	const res = await Axios.post(
		`${BASE_URL}:lookup?key=${config.firebaseApiKey}`,
		{
			idToken
		}
	);
	return await res.data;
};

export const verifyEmail = async idToken => {
	const res = await Axios.post(
		`${BASE_URL}:sendOobCode?key=${config.firebaseApiKey}`,
		{
			requestType: "VERIFY_EMAIL",
			idToken
		}
	);
	return await res.data;
};

export const updateFireBaseName = async (idToken, displayName) => {
	const res = await Axios.post(
		`${BASE_URL}:update?key=${config.firebaseApiKey}`,
		{
			idToken,
			displayName,
			returnSecureToken: true
		}
	);
	return await res.data;
};
