import React from "react";
import "./PeopleCard.css";

import Button from "../../Common/Button/Button";

const PeopleCard = ({
	v: { firstname, lastname, clients, trainer },
	history
}) => {
	return (
		<div className="PeopleCard-container">
			<div className="mock-img"></div>
			<section>
				<b>
					{firstname} {lastname}
				</b>
				<div>
					<sup>{trainer && `Trainer - ${clients} clients`}</sup>
				</div>
			</section>
			<div className="btn-box">
				<Button
					label="Connect"
					type="primary"
					className="ppl-card-btns"
					onClick={() => {}}
				/>
				<Button
					label="View Profile"
					type="primary"
					className="ppl-card-btns"
					onClick={() => {
						history.push(
							`/fp-view-profile/${firstname} ${lastname}`
						);
					}}
				/>
			</div>
		</div>
	);
};
export default PeopleCard;
