import React from "react";

export const FORM_VALUES = {
	id: "",
	name: "",
	calories: "",
	p: "",
	c: "",
	fiber: "",
	f: "",
	serving: "",
	servingType: ""
};

export const HEADERS = [
	"Name",
	"Calories",
	"Protein",
	"Carbs",
	"Fiber",
	"Fats",
	"Serving"
].map((el, i) => <th key={i}>{el}</th>);

export const Rows = ({
	name,
	calories,
	p,
	c,
	fiber,
	f,
	serving,
	servingType,
	onClick
}) => {
	return (
		<tr onClick={onClick}>
			<td>{name}</td>
			<td>{calories}</td>
			<td>{p}</td>
			<td>{c}</td>
			<td>{fiber}</td>
			<td>{f}</td>
			<td>
				{serving} {servingType}
			</td>
		</tr>
	);
};
