import React from "react";
import css from "./MacrosEditor.module.css";

export default ({ protein, carbs, fats, updateState }) => {
	const total100 =
		protein + carbs + fats === 100
			? { border: "2px solid lightgreen" }
			: { border: "2px solid red" };

	return (
		<div className={css.container}>
			<h4>Macro Percentages</h4>
			<div className={css.macroInputsContainer}>
				<section>
					<div>
						<b>
							Protein
							{/* <span style={{ backgroundColor: "lightblue" }} /> */}
						</b>
						<input
							type="number"
							min="0"
							max="100"
							step="1"
							name="protein"
							value={protein}
							onChange={updateState}
						/>
					</div>
					<div>
						<b>
							Carb
							{/* <span style={{ backgroundColor: "lightcoral" }} /> */}
						</b>
						<input
							type="number"
							min="0"
							max="100"
							step="1"
							name="carbs"
							value={carbs}
							onChange={updateState}
						/>
					</div>
					<div>
						<b>
							Fat
							{/* <span style={{ backgroundColor: "lightgreen" }} /> */}
						</b>
						<input
							type="number"
							min="0"
							max="100"
							step="1"
							name="fats"
							value={fats}
							onChange={updateState}
						/>
					</div>
					<div>
						<b>
							Total
							{/* <span style={{ backgroundColor: "lightgreen" }} /> */}
						</b>
						<input
							readOnly
							value={protein + carbs + fats}
							style={total100}
						/>
					</div>
				</section>
			</div>
		</div>
	);
};
