import React, { useState } from "react";

import "./EditMealFoodModal.css";

import EditItem from "../EditItem/EditItem";

import { FORM_VALUES } from "../../../../FoodLibrary/utils";

const EditMealFoodModal = ({ modalState, close, save }) => {
	const [state, setState] = useState(modalState.values || FORM_VALUES),
		{
			id,
			name,
			calories,
			p,
			c,
			fiber,
			f,
			serving,
			servingType,
			original = {}
		} = state,
		saveChanges = food => {
			if (!food.id) {
				food.id = "manualEntry";
			}
			"edit" in modalState
				? save(food, modalState.index, NaN, modalState.edit)
				: save(food, modalState.index);
			close();
		},
		cancel = () => {
			setState(FORM_VALUES);
			close();
		};

	return (
		<div className="EditMealFoodModal-container">
			<header className="bg-white px-2 py-3 text-center">
				<h4 className="m-0">
					<strong>
						{`Update ${modalState.mealName ||
							`Meal #${modalState.index + 1}`}`}
					</strong>
				</h4>
			</header>
			<main>
				<EditItem
					id={id}
					name={name}
					calories={original.calories || calories}
					p={original.p || p}
					c={original.c || c}
					fiber={original.fiber || fiber}
					f={original.f || f}
					serving={original.serving || serving}
					servingType={servingType}
					editMode={true}
					original={original}
					cancel={cancel}
					submit={saveChanges}
				/>
			</main>
		</div>
	);
};
export default EditMealFoodModal;
