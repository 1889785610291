import React from "react";
import css from "./MacroProgress.module.css";

export default ({
	macros = {
		protein: 40,
		carbs: 40,
		fats: 20
	},
	selected,
	targetCalories,
	plannedUsage
}) => {
	const { protein, carbs, fats } = macros;
	let width = 0,
		planned = plannedUsage[selected] || 0,
		target = 0;

	if (selected === "usedP") {
		target = (targetCalories * (protein / 100)) / 4;
	} else if (selected === "usedC") {
		target = (targetCalories * (carbs / 100)) / 4;
	} else if (selected === "usedF") {
		target = (targetCalories * (fats / 100)) / 9;
	}

	width = (planned / target) * 100;
	if (isNaN(width) || !isFinite(width)) width = 0;
	else if (width > 100) width = 100;
	const progressBar = width < 100 ? css.progressBar : css.fullProgress;
	return (
		<div className={css.container}>
			<div className={css.barContainer}>
				<div
					className={progressBar}
					style={{ height: "100%", width: `${width}%` }}
				/>
				<div className={css.plannedMetric}>{planned.toFixed(1)} g</div>
				<div className={css.targetMetric}>{target.toFixed(1)} g</div>
			</div>
		</div>
	);
};
